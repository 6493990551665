import styled from 'styled-components'
import Logo from '@/src/ui/components/Logo'
import { Link } from '@/src/ui/components/Link'
import useTheme from '@/src/ui/theme'
import { RouteName } from '@/src/routes/RouteName'
import { Tab } from '@/src/ui/components/Tab'
import { useState, createContext } from 'react'

export const SidebarContext = createContext<{ hovered: boolean }>({
  hovered: false,
})

export const Sidebar = () => {
  const { theme } = useTheme()
  const [hovered, setHovered] = useState(false)

  return (
    <SidebarContext.Provider value={{ hovered }}>
      <SidebarWrapper
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        hovered={hovered}
      >
        <LogoLink id="sidebar-analytics" to={RouteName.ANALYTICS}>
          <Logo
            variant={hovered ? 'default' : 'iconOnly'}
            data-testid="partner-logo"
            color={theme.colors.black}
          />
        </LogoLink>
        <Tab
          label="Getting started"
          route={RouteName.HOME}
          icon="pass"
          exactRoute={true}
        />
        <Tab label="Analytics" route={RouteName.ANALYTICS} icon="pieChart" />
        <Tab
          label="Businesses"
          icon="briefcase"
          route={RouteName.BUSINESSES}
          exactRoute
          subTabs={[
            { label: 'Capital', route: `${RouteName.BUSINESSES_LIST}/capital` },
          ]}
        />
        <Tab label="Data share" route={RouteName.DATA_SHARE} icon="stack" />
        <Tab
          label="Marketing"
          route={`${RouteName.MARKETING}`}
          icon="envelope"
        />
        <Tab
          label="Developer"
          route={`${RouteName.DEVELOPER}`}
          icon="developer"
        />
        <Tab label="Settings" route={`${RouteName.SETTINGS}`} icon="control" />
      </SidebarWrapper>
    </SidebarContext.Provider>
  )
}

const SidebarWrapper = styled.div<{ hovered: boolean }>`
  width: 240px;
  /* padding: 0 24px; */
  background: ${({ theme }) => theme.colors.mono200};
  transition: width 0.2s;
  min-height: 100vh;
  position: fixed;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1208px) {
    ${({ hovered }) =>
      hovered && `width: 240px; box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.1);`}
    ${({ hovered }) => !hovered && `width: 64px;`}
    /* width: 64px;
    &:hover {
      width: 240px;
      box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.1);
    } */
    z-index: 100;
  }
`

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 192px;
  height: 80px;
  display: flex;
  padding-left: 18px;
`
