import { useQuery as i, useMutation as c } from "@tanstack/react-query";
import o from "axios";
const Vd = {
  cash_advance_offer_created: "cash_advance_offer_created",
  cash_advance_offer_accepted: "cash_advance_offer_accepted",
  cash_advance_top_up_eligible: "cash_advance_top_up_eligible",
  cash_advance_paid_off: "cash_advance_paid_off",
  cash_advance_closed: "cash_advance_closed",
  cash_advance_offer_closed: "cash_advance_offer_closed",
  flex_loan_offer_accepted: "flex_loan_offer_accepted",
  flex_loan_closed: "flex_loan_closed",
  flex_loan_offer_created: "flex_loan_offer_created",
  flex_loan_offer_closed: "flex_loan_offer_closed",
  capital_product_offercreated: "capital_product_offer.created",
  capital_product_offerclosed: "capital_product_offer.closed",
  capital_product_offerdenied: "capital_product_offer.denied",
  capital_productcreated: "capital_product.created",
  capital_productclosed: "capital_product.closed",
  capital_product_applicationupdated: "capital_product_application.updated",
  opt_in: "opt_in",
  fis_opt_in_merchant_account: "fis_opt_in_merchant_account",
  fis_opt_in_bank_account: "fis_opt_in_bank_account",
  wallet_applicationapproved: "wallet_application.approved",
  wallet_applicationdenied: "wallet_application.denied",
  wallet_accountactive: "wallet_account.active",
  wallet_accountinactive: "wallet_account.inactive",
  wallet_transactionsucceeded: "wallet_transaction.succeeded",
  wallet_transactionpending: "wallet_transaction.pending",
  wallet_transactioncanceled: "wallet_transaction.canceled",
  wallet_transactionarchived: "wallet_transaction.archived",
  wallet_transactionfailed: "wallet_transaction.failed"
}, Hd = {
  normal: "normal",
  sync: "sync"
}, Yd = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, jd = {
  enabled: "enabled",
  disabled: "disabled"
}, Xd = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, Jd = {
  automatically_verified: "automatically_verified",
  pending_automatic_verification: "pending_automatic_verification",
  pending_manual_verification: "pending_manual_verification",
  manually_verified: "manually_verified",
  verification_expired: "verification_expired",
  verification_failed: "verification_failed",
  database_matched: "database_matched",
  database_insights_pass: "database_insights_pass",
  database_insights_pass_with_caution: "database_insights_pass_with_caution",
  database_insights_fail: "database_insights_fail"
}, Zd = {
  Error: "Error",
  Warning: "Warning"
}, t_ = {
  admin: "admin",
  user: "user",
  controller: "controller"
}, e_ = {
  offer: "offer",
  no_offer: "no_offer",
  manual_review: "manual_review"
}, n_ = {
  terms_of_service: "terms_of_service",
  state_disclosure: "state_disclosure",
  net_top_up_itemization: "net_top_up_itemization"
}, r_ = {
  payout: "payout",
  balance_transaction: "balance_transaction"
}, s_ = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT"
}, a_ = {
  mfa: "mfa",
  otp: "otp",
  account_recovery: "account_recovery"
}, o_ = {
  very_high: "very_high",
  high: "high",
  medium: "medium",
  low: "low"
}, u_ = {
  capital_product_plaid_bank_linking: "capital_product_plaid_bank_linking",
  capital_product_acceptance: "capital_product_acceptance",
  merchant_bank_card_transaction: "merchant_bank_card_transaction",
  merchant_bank_transfer: "merchant_bank_transfer"
}, i_ = {
  pending_response: "pending_response",
  completed: "completed"
}, c_ = {
  before_marketing: "before_marketing",
  after_sign_up: "after_sign_up"
}, y_ = {
  capital_product_offers_table: "capital_product_offers_table",
  capital_product_applications_table: "capital_product_applications_table",
  capital_products_table: "capital_products_table"
}, d_ = {
  completed: "completed",
  in_progress: "in_progress",
  not_started: "not_started",
  failed: "failed"
}, __ = {
  shopify: "shopify",
  amazon: "amazon",
  square: "square",
  ebay: "ebay",
  walmart: "walmart",
  etsy: "etsy",
  woo_commerce: "woo_commerce",
  quickbooks: "quickbooks",
  xero: "xero",
  net_suite: "net_suite",
  magneto: "magneto",
  freshbooks: "freshbooks",
  zohobooks: "zohobooks",
  clover: "clover",
  paypal: "paypal"
}, p_ = {
  payments: "payments",
  accounting: "accounting"
}, l_ = {
  pending: "pending",
  available: "available",
  disconnected: "disconnected",
  disabled: "disabled",
  link_error: "link_error",
  error: "error"
}, m_ = {
  duplicate: "duplicate",
  incorrect_amount: "incorrect_amount",
  incorrect_receiving_account: "incorrect_receiving_account",
  date_earlier_than_intended: "date_earlier_than_intended",
  date_later_than_intended: "date_later_than_intended"
}, g_ = {
  R01: "R01",
  R02: "R02",
  R03: "R03",
  R04: "R04",
  R05: "R05",
  R06: "R06",
  R07: "R07",
  R08: "R08",
  R09: "R09",
  R10: "R10",
  R11: "R11",
  R12: "R12",
  R13: "R13",
  R14: "R14",
  R15: "R15",
  R16: "R16",
  R17: "R17",
  R18: "R18",
  R19: "R19",
  R20: "R20",
  R21: "R21",
  R22: "R22",
  R23: "R23",
  R24: "R24",
  R25: "R25",
  R26: "R26",
  R27: "R27",
  R28: "R28",
  R29: "R29",
  R30: "R30",
  R31: "R31",
  R32: "R32",
  R33: "R33",
  R34: "R34",
  R35: "R35",
  R36: "R36",
  R37: "R37",
  R38: "R38",
  R39: "R39",
  R45: "R45",
  C01: "C01",
  C02: "C02",
  C03: "C03",
  C05: "C05",
  C06: "C06",
  C07: "C07",
  C08: "C08",
  C09: "C09",
  C13: "C13",
  C14: "C14",
  NUMBER_900: 900,
  NUMBER_901: 901,
  NUMBER_902: 902,
  NUMBER_903: 903,
  NUMBER_904: 904,
  NUMBER_905: 905,
  NUMBER_907: 907,
  NUMBER_908: 908,
  NUMBER_909: 909,
  NUMBER_910: 910,
  NUMBER_911: 911,
  NUMBER_912: 912,
  NUMBER_914: 914,
  NUMBER_915: 915,
  NUMBER_916: 916,
  NUMBER_917: 917,
  NUMBER_918: 918,
  NUMBER_919: 919,
  NUMBER_920: 920,
  NUMBER_921: 921,
  NUMBER_922: 922,
  NUMBER_990: 990
}, q_ = {
  prevent_business_onboarding: "prevent_business_onboarding",
  suspend_card_account: "suspend_card_account"
}, f_ = {
  manual: "manual",
  auto_expiration: "auto_expiration",
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  lift_repeated_failed_nsf_repayments: "lift_repeated_failed_nsf_repayments",
  unverified_bank_account: "unverified_bank_account",
  lift_unverified_bank_account: "lift_unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  lift_recent_non_nsf_repayment_returns: "lift_recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  lift_first_repayment_returned_nsf: "lift_first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  lift_first_auto_repayment_returned_nsf: "lift_first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  lift_no_recent_sales: "lift_no_recent_sales",
  plaid_transaction_review: "plaid_transaction_review",
  generic_application_denial: "generic_application_denial"
}, O_ = {
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  unverified_bank_account: "unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  fraud: "fraud",
  bankruptcy: "bankruptcy",
  active_payment_plan_high_risk: "active_payment_plan_high_risk",
  payment_processor_transition: "payment_processor_transition",
  shared_email_across_platforms: "shared_email_across_platforms",
  bank_account_high_servicing_risk: "bank_account_high_servicing_risk",
  servicing_risk: "servicing_risk",
  generic_application_denial: "generic_application_denial"
}, h_ = {
  unverified_bank_account: "unverified_bank_account",
  repayment_returns: "repayment_returns",
  other: "other"
}, K_ = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, b_ = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly"
}, k_ = {
  cx: "cx",
  pending_funding: "pending_funding",
  too_many_missed_repayments: "too_many_missed_repayments",
  administrative_ach_return: "administrative_ach_return",
  awaiting_retry_non_nsf_return: "awaiting_retry_non_nsf_return",
  pending_reversal_completion: "pending_reversal_completion"
}, M_ = {
  gross_sales: "gross_sales",
  net_payouts: "net_payouts"
}, P_ = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, x_ = {
  pending: "pending",
  approved: "approved",
  denied: "denied",
  manual_review: "manual_review"
}, B_ = {
  other: "other",
  disbursements: "disbursements",
  serviceability: "serviceability"
}, R_ = {
  mfa_phone_number: "mfa_phone_number",
  unverified_phone_number: "unverified_phone_number"
}, F_ = {
  NUMBER_93: "93",
  NUMBER_355: "355",
  NUMBER_213: "213",
  NUMBER_1684: "1684",
  NUMBER_376: "376",
  NUMBER_244: "244",
  NUMBER_1264: "1264",
  NUMBER_672: "672",
  NUMBER_1268: "1268",
  NUMBER_54: "54",
  NUMBER_374: "374",
  NUMBER_297: "297",
  NUMBER_61: "61",
  NUMBER_43: "43",
  NUMBER_994: "994",
  NUMBER_1242: "1242",
  NUMBER_973: "973",
  NUMBER_880: "880",
  NUMBER_1246: "1246",
  NUMBER_375: "375",
  NUMBER_32: "32",
  NUMBER_501: "501",
  NUMBER_229: "229",
  NUMBER_1441: "1441",
  NUMBER_975: "975",
  NUMBER_591: "591",
  NUMBER_387: "387",
  NUMBER_267: "267",
  NUMBER_55: "55",
  NUMBER_246: "246",
  NUMBER_1284: "1284",
  NUMBER_673: "673",
  NUMBER_359: "359",
  NUMBER_226: "226",
  NUMBER_257: "257",
  NUMBER_855: "855",
  NUMBER_237: "237",
  NUMBER_1: "1",
  NUMBER_238: "238",
  NUMBER_1345: "1345",
  NUMBER_236: "236",
  NUMBER_235: "235",
  NUMBER_56: "56",
  NUMBER_86: "86",
  NUMBER_57: "57",
  NUMBER_269: "269",
  NUMBER_682: "682",
  NUMBER_506: "506",
  NUMBER_385: "385",
  NUMBER_53: "53",
  NUMBER_599: "599",
  NUMBER_357: "357",
  NUMBER_420: "420",
  NUMBER_243: "243",
  NUMBER_45: "45",
  NUMBER_253: "253",
  "1-767": "1-767",
  NUMBER_1809: "1809",
  NUMBER_1829: "1829",
  NUMBER_1849: "1849",
  NUMBER_670: "670",
  NUMBER_593: "593",
  NUMBER_20: "20",
  NUMBER_503: "503",
  NUMBER_240: "240",
  NUMBER_291: "291",
  NUMBER_372: "372",
  NUMBER_251: "251",
  NUMBER_500: "500",
  NUMBER_298: "298",
  NUMBER_679: "679",
  NUMBER_358: "358",
  NUMBER_33: "33",
  NUMBER_689: "689",
  NUMBER_241: "241",
  NUMBER_220: "220",
  NUMBER_995: "995",
  NUMBER_49: "49",
  NUMBER_233: "233",
  NUMBER_350: "350",
  NUMBER_30: "30",
  NUMBER_299: "299",
  NUMBER_1473: "1473",
  NUMBER_1671: "1671",
  NUMBER_502: "502",
  NUMBER_441481: "441481",
  NUMBER_224: "224",
  NUMBER_245: "245",
  NUMBER_592: "592",
  NUMBER_509: "509",
  NUMBER_504: "504",
  NUMBER_852: "852",
  NUMBER_36: "36",
  NUMBER_354: "354",
  NUMBER_91: "91",
  NUMBER_62: "62",
  NUMBER_98: "98",
  NUMBER_964: "964",
  NUMBER_353: "353",
  NUMBER_441624: "441624",
  NUMBER_972: "972",
  NUMBER_39: "39",
  NUMBER_225: "225",
  NUMBER_1876: "1876",
  NUMBER_81: "81",
  NUMBER_441534: "441534",
  NUMBER_962: "962",
  NUMBER_7: "7",
  NUMBER_254: "254",
  NUMBER_686: "686",
  NUMBER_383: "383",
  NUMBER_965: "965",
  NUMBER_996: "996",
  NUMBER_856: "856",
  NUMBER_371: "371",
  NUMBER_961: "961",
  NUMBER_266: "266",
  NUMBER_231: "231",
  NUMBER_218: "218",
  NUMBER_423: "423",
  NUMBER_370: "370",
  NUMBER_352: "352",
  NUMBER_853: "853",
  NUMBER_389: "389",
  NUMBER_261: "261",
  NUMBER_265: "265",
  NUMBER_60: "60",
  NUMBER_960: "960",
  NUMBER_223: "223",
  NUMBER_356: "356",
  NUMBER_692: "692",
  NUMBER_222: "222",
  NUMBER_230: "230",
  NUMBER_262: "262",
  NUMBER_52: "52",
  NUMBER_691: "691",
  NUMBER_373: "373",
  NUMBER_377: "377",
  NUMBER_976: "976",
  NUMBER_382: "382",
  "1-664": "1-664",
  NUMBER_212: "212",
  NUMBER_258: "258",
  NUMBER_95: "95",
  NUMBER_264: "264",
  NUMBER_674: "674",
  NUMBER_977: "977",
  NUMBER_31: "31",
  NUMBER_687: "687",
  NUMBER_64: "64",
  NUMBER_505: "505",
  NUMBER_227: "227",
  NUMBER_234: "234",
  NUMBER_683: "683",
  NUMBER_850: "850",
  NUMBER_1670: "1670",
  NUMBER_47: "47",
  NUMBER_968: "968",
  NUMBER_92: "92",
  NUMBER_680: "680",
  NUMBER_970: "970",
  NUMBER_507: "507",
  NUMBER_675: "675",
  NUMBER_595: "595",
  NUMBER_51: "51",
  NUMBER_63: "63",
  NUMBER_48: "48",
  NUMBER_351: "351",
  NUMBER_1787: "1787",
  NUMBER_1939: "1939",
  NUMBER_974: "974",
  NUMBER_242: "242",
  NUMBER_40: "40",
  NUMBER_250: "250",
  NUMBER_590: "590",
  NUMBER_290: "290",
  NUMBER_1869: "1869",
  NUMBER_1758: "1758",
  NUMBER_508: "508",
  NUMBER_1784: "1784",
  NUMBER_685: "685",
  NUMBER_378: "378",
  NUMBER_239: "239",
  NUMBER_966: "966",
  NUMBER_221: "221",
  NUMBER_381: "381",
  NUMBER_248: "248",
  NUMBER_232: "232",
  NUMBER_65: "65",
  NUMBER_1721: "1721",
  NUMBER_421: "421",
  NUMBER_386: "386",
  NUMBER_677: "677",
  NUMBER_252: "252",
  NUMBER_27: "27",
  NUMBER_82: "82",
  NUMBER_211: "211",
  NUMBER_34: "34",
  NUMBER_94: "94",
  NUMBER_249: "249",
  NUMBER_597: "597",
  NUMBER_268: "268",
  NUMBER_46: "46",
  NUMBER_41: "41",
  NUMBER_963: "963",
  NUMBER_886: "886",
  NUMBER_992: "992",
  NUMBER_255: "255",
  NUMBER_66: "66",
  NUMBER_228: "228",
  NUMBER_690: "690",
  NUMBER_676: "676",
  NUMBER_1868: "1868",
  NUMBER_216: "216",
  NUMBER_90: "90",
  NUMBER_993: "993",
  NUMBER_1649: "1649",
  NUMBER_688: "688",
  NUMBER_1340: "1340",
  NUMBER_256: "256",
  NUMBER_380: "380",
  NUMBER_971: "971",
  NUMBER_44: "44",
  NUMBER_598: "598",
  NUMBER_998: "998",
  NUMBER_678: "678",
  NUMBER_379: "379",
  NUMBER_58: "58",
  NUMBER_84: "84",
  NUMBER_681: "681",
  NUMBER_967: "967",
  NUMBER_260: "260",
  NUMBER_263: "263"
}, E_ = {
  BUSINESS: "BUSINESS",
  BUSINESSSUPPLEMENTALDOCUMENTS: "BUSINESSSUPPLEMENTALDOCUMENTS",
  GOVID: "GOVID",
  ADHOCBUSINESSNAMEDOCUMENT: "ADHOCBUSINESSNAMEDOCUMENT",
  ADHOCBUSINESSADDRESSDOCUMENT: "ADHOCBUSINESSADDRESSDOCUMENT",
  ADHOCBUSINESSSTATUSDOCUMENT: "ADHOCBUSINESSSTATUSDOCUMENT",
  ADHOCHOMEADDRESSDOCUMENT: "ADHOCHOMEADDRESSDOCUMENT",
  ADHOCEINDOCUMENT: "ADHOCEINDOCUMENT",
  ADHOCIDENTITYDOCUMENT: "ADHOCIDENTITYDOCUMENT"
}, C_ = {
  merchant_cash_advance: "merchant_cash_advance",
  card: "card",
  flex_loan: "flex_loan",
  merchant_bank: "merchant_bank"
}, v_ = {
  in_progress: "in_progress",
  completed: "completed",
  failed: "failed"
}, U_ = {
  void_cash_advance: "void_cash_advance"
}, N_ = {
  basic_offer_generation: "basic_offer_generation",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  kyc_document_upload: "kyc_document_upload",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_reverification: "bank_reverification",
  bank_pad_agreement: "bank_pad_agreement",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  amazon_complete: "amazon_complete",
  advance_acceptance: "advance_acceptance",
  flex_loan_acceptance: "flex_loan_acceptance",
  offer_acceptance: "offer_acceptance",
  loan_acceptance: "loan_acceptance",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  manual_review: "manual_review",
  offer_accepted: "offer_accepted",
  offer_preview: "offer_preview",
  advance: "advance",
  flex_loan: "flex_loan",
  loan: "loan",
  capital_product: "capital_product",
  denied: "denied",
  abandoned: "abandoned",
  merchant_bank_pre_onboarding: "merchant_bank_pre_onboarding",
  merchant_bank_created: "merchant_bank_created",
  contact_verification: "contact_verification",
  start_application: "start_application"
}, D_ = {
  merchant_cash_advance: "merchant_cash_advance",
  loan: "loan",
  merchant_bank: "merchant_bank"
}, A_ = {
  basic_offer_generation: "basic_offer_generation",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_pad_agreement: "bank_pad_agreement",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_reverification: "bank_reverification",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  advance_acceptance: "advance_acceptance",
  flex_loan_acceptance: "flex_loan_acceptance",
  loan_acceptance: "loan_acceptance",
  kyc_document_upload: "kyc_document_upload",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  amazon_complete: "amazon_complete",
  start_application: "start_application",
  offer_preview: "offer_preview"
}, S_ = {
  top_up_reminder: "top_up_reminder",
  fast_payouts_notification_card: "fast_payouts_notification_card"
}, G_ = {
  minimum: "minimum",
  sales_based: "sales_based"
}, I_ = {
  unverified: "unverified",
  posted: "posted",
  reversed: "reversed",
  verified: "verified",
  failed: "failed"
}, T_ = {
  ach: "ach",
  same_day_ach: "same_day_ach",
  rtp: "rtp",
  wire: "wire"
}, w_ = {
  inner_transfer: "inner_transfer",
  card_transaction: "card_transaction",
  recipient_transfer: "recipient_transfer",
  external_bank_transfer: "external_bank_transfer",
  received_transfer: "received_transfer",
  transfer_return: "transfer_return",
  transfer_reversal: "transfer_reversal",
  repayment: "repayment"
}, Q_ = {
  succeeded: "succeeded",
  failed: "failed",
  pending: "pending",
  cancelled: "cancelled",
  archived: "archived"
}, L_ = {
  money_in: "money_in",
  money_out: "money_out"
}, W_ = {
  merchant_bank_account: "merchant_bank_account",
  recipient_account: "recipient_account",
  external_bank_account: "external_bank_account"
}, z_ = {
  manual: "manual",
  payout: "payout"
}, $_ = {
  electrical: "electrical",
  wire: "wire"
}, V_ = {
  normal: "normal",
  micro_deposit: "micro_deposit",
  partner_payout: "partner_payout"
}, H_ = {
  checking: "checking",
  savings: "savings"
}, Y_ = {
  active: "active",
  unlinked: "unlinked",
  archived_due_to_update: "archived_due_to_update",
  unverified_micro_deposit: "unverified_micro_deposit",
  failed_micro_deposit: "failed_micro_deposit"
}, j_ = {
  physical: "physical",
  virtual: "virtual"
}, X_ = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, J_ = {
  cash: "cash",
  credit: "credit"
}, Z_ = {
  user_submitted: "user_submitted",
  parafin_rejected: "parafin_rejected",
  parafin_pending: "parafin_pending",
  parafin_submitted: "parafin_submitted",
  marqeta_rejected: "marqeta_rejected",
  marqeta_chargeback_won: "marqeta_chargeback_won",
  marqeta_chargeback_lost: "marqeta_chargeback_lost",
  parafin_credited: "parafin_credited",
  parafin_not_credited: "parafin_not_credited"
}, tp = {
  did_not_purchase: "did_not_purchase",
  charged_for_cancelled_or_returned_purchase: "charged_for_cancelled_or_returned_purchase",
  incorrect_amount: "incorrect_amount",
  never_received: "never_received",
  expecting_credit: "expecting_credit",
  not_as_described_or_defective: "not_as_described_or_defective",
  other: "other"
}, ep = {
  contact_verification: "contact_verification",
  kyc: "kyc",
  kyc_document_upload: "kyc_document_upload",
  kyc_submitted: "kyc_submitted",
  kyc_approved: "kyc_approved",
  manual_review: "manual_review",
  merchant_bank_created: "merchant_bank_created",
  denied: "denied"
}, np = {
  checking: "checking",
  savings: "savings"
}, rp = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, sp = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  statement_upload: "statement_upload",
  plaid_investigation: "plaid_investigation",
  manual_review: "manual_review",
  liens_pending: "liens_pending"
}, ap = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, op = {
  ucc: "ucc",
  federal: "federal",
  state: "state"
}, up = {
  open: "open",
  closed: "closed",
  unknown: "unknown"
}, ip = {
  name: "name",
  address: "address",
  watchlist: "watchlist",
  ein: "ein",
  id: "id",
  date_of_birth: "date_of_birth",
  date_of_birth_miskey: "date_of_birth_miskey",
  ssn_miskey: "ssn_miskey",
  other_warning: "other_warning"
}, cp = {
  alloy: "alloy",
  marqeta: "marqeta",
  middesk: "middesk",
  persona: "persona",
  sandbox: "sandbox",
  unplugged: "unplugged"
}, yp = {
  checking_to_savings: "checking_to_savings",
  savings_to_checking: "savings_to_checking"
}, dp = {
  not_started: "not_started",
  pending: "pending",
  success: "success",
  failed: "failed"
}, _p = {
  irrelevant: "irrelevant",
  incomplete: "incomplete",
  complete: "complete"
}, pp = {
  llc: "llc",
  corporation: "corporation",
  sole_proprietorship: "sole_proprietorship",
  partnership: "partnership",
  cooperative: "cooperative",
  business_trust: "business_trust",
  joint_venture: "joint_venture",
  other: "other"
}, lp = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT",
  Federal: "Federal"
}, mp = {
  all_merchants: "all_merchants",
  post_opt_in: "post_opt_in",
  post_opt_in_sync_webhook: "post_opt_in_sync_webhook",
  never: "never"
}, gp = {
  upcoming_repayment: "upcoming_repayment",
  scheduled_repayment: "scheduled_repayment"
}, qp = {
  manual: "manual",
  rutter: "rutter"
}, fp = {
  string: "string",
  decimal: "decimal",
  int: "int",
  date: "date",
  object: "object",
  currency_code: "currency_code",
  boolean: "boolean"
}, Op = {
  business: "business",
  account: "account"
}, hp = {
  BUSINESS_GROUP_ADMIN: "BUSINESS GROUP ADMIN",
  ADMIN: "ADMIN",
  "N/A": "N/A",
  MANAGER: "MANAGER",
  STORE_OPERATOR: "STORE OPERATOR"
}, Kp = {
  kyc: "kyc",
  bank_verification: "bank_verification",
  financial_review: "financial_review",
  tax_record: "tax_record"
}, bp = {
  apply: "apply",
  lift: "lift"
}, kp = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  location: "location",
  program_terminated: "program_terminated",
  no_amazon_offer: "no_amazon_offer"
}, Mp = {
  file_feed: "file_feed",
  manual_upload: "manual_upload",
  standard_s3: "standard_s3",
  api: "api",
  custom_legacy: "custom_legacy",
  opt_in: "opt_in"
}, Pp = {
  pending: "pending",
  success: "success",
  failed: "failed"
}, xp = {
  business: "business",
  sale_record: "sale_record",
  bank_account_info: "bank_account_info",
  person: "person",
  person_business_relationship: "person_business_relationship"
}, Bp = {
  irrelevant: "irrelevant",
  optional: "optional",
  required: "required"
}, Rp = {
  UNSUPPORTEDCURRENCY: "UNSUPPORTEDCURRENCY",
  USD: "USD",
  CAD: "CAD",
  USDFOURTHDECIMAL: "USDFOURTHDECIMAL"
}, Fp = {
  hubspot: "hubspot",
  marketo: "marketo",
  salesforce: "salesforce"
}, Ep = {
  offer_summary: "offer_summary"
}, Cp = {
  pending: "pending",
  succeeded: "succeeded",
  failed: "failed"
}, vp = {
  US: "US",
  CA: "CA"
}, Up = {
  non_payment: "non_payment",
  slow_payment: "slow_payment",
  platform_exit: "platform_exit",
  bankruptcy: "bankruptcy",
  death: "death",
  fraud: "fraud",
  error: "error"
}, Np = {
  non_payment: "non_payment",
  slow_payment: "slow_payment",
  platform_exit: "platform_exit",
  bankruptcy: "bankruptcy",
  death: "death",
  fraud: "fraud",
  error: "error"
}, Dp = {
  fulfillmentdigitally_presented: "fulfillment.digitally_presented",
  fulfillmentissued: "fulfillment.issued",
  fulfillmentordered: "fulfillment.ordered",
  fulfillmentrejected: "fulfillment.rejected",
  fulfillmentreordered: "fulfillment.reordered",
  fulfillmentshipped: "fulfillment.shipped",
  stateactivated: "state.activated",
  statereinstated: "state.reinstated",
  statesuspended: "state.suspended",
  stateterminated: "state.terminated",
  pinchanged: "pin.changed",
  pinset: "pin.set",
  pinreveal: "pin.reveal"
}, Ap = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNACTIVATED: "UNACTIVATED"
}, Sp = {
  NUMBER_00: "00",
  NUMBER_01: "01",
  NUMBER_02: "02",
  NUMBER_03: "03",
  NUMBER_04: "04",
  NUMBER_05: "05",
  NUMBER_06: "06",
  NUMBER_07: "07",
  NUMBER_08: "08",
  NUMBER_09: "09",
  NUMBER_10: "10",
  NUMBER_11: "11",
  NUMBER_12: "12",
  NUMBER_13: "13",
  NUMBER_14: "14",
  NUMBER_15: "15",
  NUMBER_16: "16",
  NUMBER_17: "17",
  NUMBER_18: "18",
  NUMBER_19: "19",
  NUMBER_20: "20",
  NUMBER_21: "21",
  NUMBER_22: "22",
  NUMBER_23: "23",
  NUMBER_24: "24",
  NUMBER_25: "25",
  NUMBER_26: "26",
  NUMBER_27: "27",
  NUMBER_28: "28",
  NUMBER_29: "29",
  NUMBER_30: "30",
  NUMBER_31: "31"
}, Gp = {
  ADMIN: "ADMIN",
  API: "API",
  FRAUD: "FRAUD",
  IVR: "IVR",
  SYSTEM: "SYSTEM"
}, Ip = {
  LOCAL_MAIL: "LOCAL_MAIL",
  GROUND: "GROUND",
  TWO_DAY: "TWO_DAY",
  OVERNIGHT: "OVERNIGHT",
  INTERNATIONAL: "INTERNATIONAL",
  FEDEX_EXPEDITED: "FEDEX_EXPEDITED",
  FEDEX_REGULAR: "FEDEX_REGULAR",
  UPS_EXPEDITED: "UPS_EXPEDITED",
  UPS_REGULAR: "UPS_REGULAR",
  USPS_EXPEDITED: "USPS_EXPEDITED",
  USPS_REGULAR: "USPS_REGULAR"
}, Tp = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, wp = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, Qp = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, Lp = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, Wp = {
  physical: "physical",
  virtual: "virtual"
}, zp = {
  physical: "physical",
  virtual: "virtual"
}, $p = {
  no_opt_in: "no_opt_in",
  opt_in: "opt_in",
  onboarding: "onboarding",
  offer_expired: "offer_expired",
  kyc_review: "kyc_review",
  kyc_failed: "kyc_failed",
  verify_bank: "verify_bank",
  reverify_bank: "reverify_bank",
  no_transaction: "no_transaction",
  complete: "complete",
  account_closed: "account_closed"
}, Vp = {
  merchant_cash_advance: "merchant_cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan"
}, Hp = {
  outstanding: "outstanding",
  paid: "paid",
  abandoned: "abandoned",
  cancelled: "cancelled",
  waived: "waived",
  void: "void"
}, Yp = {
  "1month": "1month",
  "2month": "2month",
  "3month": "3month",
  "4month": "4month",
  "5month": "5month",
  "6month": "6month",
  "7month": "7month",
  "8month": "8month",
  "9month": "9month",
  "10month": "10month",
  "11month": "11month",
  "12month": "12month",
  "13month": "13month",
  "14month": "14month",
  "15month": "15month",
  "16month": "16month",
  "17month": "17month",
  "18month": "18month",
  "19month": "19month",
  "20month": "20month",
  "21month": "21month",
  "22month": "22month",
  "23month": "23month",
  "24month": "24month"
}, jp = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, Xp = {
  healthy: "healthy",
  unhealthy: "unhealthy"
}, Jp = {
  merchant_cash_advance: "merchant_cash_advance",
  amazon_mca: "amazon_mca",
  flex_loan: "flex_loan",
  term_loan: "term_loan"
}, Zp = {
  in_progress: "in_progress",
  manual_review: "manual_review",
  approved: "approved",
  denied: "denied",
  expired: "expired",
  abandoned: "abandoned",
  cancelled: "cancelled",
  withdrawn: "withdrawn",
  offer_accepted: "offer_accepted",
  funded: "funded"
}, tl = {
  principal: "principal",
  fee: "fee",
  payment: "payment",
  minimum_payment: "minimum_payment",
  automatic_payment: "automatic_payment",
  manual_payment: "manual_payment",
  generic: "generic",
  payment_adjustment: "payment_adjustment"
}, el = {
  card: "card",
  cash_advance: "cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  merchant_bank: "merchant_bank"
}, nl = {
  full: "full",
  partial: "partial",
  none: "none",
  partner_does_not_have: "partner_does_not_have"
}, rl = {
  HOME: "HOME",
  WORK: "WORK",
  LEGAL: "LEGAL",
  SHIPPING: "SHIPPING",
  PHYSICAL: "PHYSICAL"
}, sl = {
  celtic_bank: "celtic_bank",
  parafin: "parafin",
  parafin_spv_1: "parafin_spv_1",
  parafin_spv_2: "parafin_spv_2",
  parafin_spv_3: "parafin_spv_3"
}, d = (t, e) => o.get(
  "/business_cores",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _ = (t) => ["/business_cores", ...t ? [t] : []], p = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _(t), queryFn: ({ signal: u }) => d(t, { signal: u, ...r }), ...n };
};
function al(t, e) {
  const n = p(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const l = (t, e) => o.post(
  "/business_cores",
  t,
  e
), m = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return l(a, n);
  }, ...e };
}, ol = (t) => {
  const e = m(t);
  return c(e);
}, g = (t, e) => o.get(
  "/business_annotations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), q = (t) => ["/business_annotations", ...t ? [t] : []], f = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? q(t), queryFn: ({ signal: u }) => g(t, { signal: u, ...r }), ...n };
};
function ul(t, e) {
  const n = f(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const O = (t, e) => o.post(
  "/business_annotations",
  t,
  e
), h = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return O(a, n);
  }, ...e };
}, il = (t) => {
  const e = h(t);
  return c(e);
}, K = (t, e) => o.get(
  "/cash_advance_charge_offs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), b = (t) => ["/cash_advance_charge_offs", ...t ? [t] : []], k = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? b(t), queryFn: ({ signal: u }) => K(t, { signal: u, ...r }), ...n };
};
function cl(t, e) {
  const n = k(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const M = (t, e) => o.post(
  "/cash_advance_charge_offs",
  t,
  e
), P = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return M(a, n);
  }, ...e };
}, yl = (t) => {
  const e = P(t);
  return c(e);
}, x = (t, e) => o.get(
  "/partners/doordash/admin_users",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), B = (t) => ["/partners/doordash/admin_users", ...t ? [t] : []], R = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? B(t), queryFn: ({ signal: u }) => x(t, { signal: u, ...r }), ...n };
};
function dl(t, e) {
  const n = R(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const F = (t, e) => o.get(
  "/cash_advance_pauses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), E = (t) => ["/cash_advance_pauses", ...t ? [t] : []], C = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? E(t), queryFn: ({ signal: u }) => F(t, { signal: u, ...r }), ...n };
};
function _l(t, e) {
  const n = C(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const v = (t, e) => o.post(
  "/cash_advance_pauses",
  t,
  e
), U = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return v(a, n);
  }, ...e };
}, pl = (t) => {
  const e = U(t);
  return c(e);
}, N = (t, e) => o.delete(
  `/cash_advance_pauses/${t}`,
  e
), D = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: a } = s ?? {};
    return N(a, n);
  }, ...e };
}, ll = (t) => {
  const e = D(t);
  return c(e);
}, A = (t, e, n) => o.patch(
  `/cash_advance_pauses/${t}`,
  e,
  n
), S = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: a, data: u } = s ?? {};
    return A(a, u, n);
  }, ...e };
}, ml = (t) => {
  const e = S(t);
  return c(e);
}, G = (t, e) => o.get(
  "/repayment_plan_parameters",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), I = (t) => ["/repayment_plan_parameters", ...t ? [t] : []], T = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? I(t), queryFn: ({ signal: u }) => G(t, { signal: u, ...r }), ...n };
};
function gl(t, e) {
  const n = T(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const w = (t, e) => o.get(
  "/future_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Q = (t) => ["/future_activities", ...t ? [t] : []], L = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Q(t), queryFn: ({ signal: u }) => w(t, { signal: u, ...r }), ...n };
};
function ql(t, e) {
  const n = L(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const W = (t, e) => o.get(
  "/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), z = (t) => ["/partners", ...t ? [t] : []], $ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? z(t), queryFn: ({ signal: u }) => W(t, { signal: u, ...r }), ...n };
};
function fl(t, e) {
  const n = $(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const V = (t, e) => o.post(
  "/partners",
  t,
  e
), H = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return V(a, n);
  }, ...e };
}, Ol = (t) => {
  const e = H(t);
  return c(e);
}, Y = (t) => o.get(
  "/partners/underwriting",
  t
), j = () => ["/partners/underwriting"], X = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? j(), queryFn: ({ signal: a }) => Y({ signal: a, ...n }), ...e };
};
function hl(t) {
  const e = X(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const J = (t, e, n) => o.patch(
  `/partners/${t}`,
  e,
  n
), Z = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return J(a, u, n);
  }, ...e };
}, Kl = (t) => {
  const e = Z(t);
  return c(e);
}, tt = (t, e) => o.get(
  "/partner/api_keys",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), et = (t) => ["/partner/api_keys", ...t ? [t] : []], nt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? et(t), queryFn: ({ signal: u }) => tt(t, { signal: u, ...r }), ...n };
};
function bl(t, e) {
  const n = nt(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const rt = (t, e) => o.post(
  "/partner/api_keys",
  t,
  e
), st = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return rt(a, n);
  }, ...e };
}, kl = (t) => {
  const e = st(t);
  return c(e);
}, at = (t) => o.get(
  "/partner/organizations",
  t
), ot = () => ["/partner/organizations"], ut = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ot(), queryFn: ({ signal: a }) => at({ signal: a, ...n }), ...e };
};
function Ml(t) {
  const e = ut(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const it = (t, e) => o.post(
  "/partner/organizations",
  t,
  e
), ct = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return it(a, n);
  }, ...e };
}, Pl = (t) => {
  const e = ct(t);
  return c(e);
}, yt = (t, e) => o.post(
  "/partner/organizations/link_partner",
  t,
  e
), dt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return yt(a, n);
  }, ...e };
}, xl = (t) => {
  const e = dt(t);
  return c(e);
}, _t = (t, e) => o.get(
  "/partner/organizations/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), pt = (t) => ["/partner/organizations/partners", ...t ? [t] : []], lt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? pt(t), queryFn: ({ signal: u }) => _t(t, { signal: u, ...r }), ...n };
};
function Bl(t, e) {
  const n = lt(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const mt = (t, e) => o.post(
  "/slack/authorize",
  t,
  e
), gt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return mt(a, n);
  }, ...e };
}, Rl = (t) => {
  const e = gt(t);
  return c(e);
}, qt = (t) => o.get(
  "/slack/channels",
  t
), ft = () => ["/slack/channels"], Ot = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ft(), queryFn: ({ signal: a }) => qt({ signal: a, ...n }), ...e };
};
function Fl(t) {
  const e = Ot(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const ht = (t, e) => o.delete(
  `/slack/channels/${t}`,
  e
), Kt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return ht(a, n);
  }, ...e };
}, El = (t) => {
  const e = Kt(t);
  return c(e);
}, bt = (t) => o.get(
  "/partner/users",
  t
), kt = () => ["/partner/users"], Mt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? kt(), queryFn: ({ signal: a }) => bt({ signal: a, ...n }), ...e };
};
function Cl(t) {
  const e = Mt(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Pt = (t, e) => o.post(
  "/partner/users",
  t,
  e
), xt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Pt(a, n);
  }, ...e };
}, vl = (t) => {
  const e = xt(t);
  return c(e);
}, Bt = (t, e) => o.patch(
  "/partner/users",
  t,
  e
), Rt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Bt(a, n);
  }, ...e };
}, Ul = (t) => {
  const e = Rt(t);
  return c(e);
}, Ft = (t, e) => o.delete(
  "/partner/delete_users",
  { data: t, ...e }
), Et = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ft(a, n);
  }, ...e };
}, Nl = (t) => {
  const e = Et(t);
  return c(e);
}, Ct = (t, e) => o.get(
  "/partner/public_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), vt = (t) => ["/partner/public_configs", ...t ? [t] : []], Ut = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? vt(t), queryFn: ({ signal: u }) => Ct(t, { signal: u, ...r }), ...n };
};
function Dl(t, e) {
  const n = Ut(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Nt = (t, e) => o.get(
  "/partner/configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Dt = (t) => ["/partner/configs", ...t ? [t] : []], At = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Dt(t), queryFn: ({ signal: u }) => Nt(t, { signal: u, ...r }), ...n };
};
function Al(t, e) {
  const n = At(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const St = (t, e, n) => o.patch(
  `/partner/configs/${t}`,
  e,
  n
), Gt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return St(a, u, n);
  }, ...e };
}, Sl = (t) => {
  const e = Gt(t);
  return c(e);
}, It = (t) => o.get(
  "/partner/sync_webhooks",
  t
), Tt = () => ["/partner/sync_webhooks"], wt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Tt(), queryFn: ({ signal: a }) => It({ signal: a, ...n }), ...e };
};
function Gl(t) {
  const e = wt(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Qt = (t, e) => o.post(
  "/partner/sync_webhooks",
  t,
  e
), Lt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Qt(a, n);
  }, ...e };
}, Il = (t) => {
  const e = Lt(t);
  return c(e);
}, Wt = (t, e) => o.delete(
  `/partner/sync_webhooks/${t}`,
  e
), zt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Wt(a, n);
  }, ...e };
}, Tl = (t) => {
  const e = zt(t);
  return c(e);
}, $t = (t, e, n) => o.patch(
  `/partner/sync_webhooks/${t}`,
  e,
  n
), Vt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return $t(a, u, n);
  }, ...e };
}, wl = (t) => {
  const e = Vt(t);
  return c(e);
}, Ht = (t, e) => o.post(
  "/auth/tokens",
  t,
  e
), Yt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ht(a, n);
  }, ...e };
}, Ql = (t) => {
  const e = Yt(t);
  return c(e);
}, jt = (t, e) => o.get(
  "/statements_v2",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xt = (t) => ["/statements_v2", ...t ? [t] : []], Jt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xt(t), queryFn: ({ signal: u }) => jt(t, { signal: u, ...r }), ...n };
};
function Ll(t, e) {
  const n = Jt(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Zt = (t, e) => o.post(
  "/marketing/review",
  t,
  e
), te = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Zt(a, n);
  }, ...e };
}, Wl = (t) => {
  const e = te(t);
  return c(e);
}, ee = (t) => o.get(
  "/marketing/integrations",
  t
), ne = () => ["/marketing/integrations"], re = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ne(), queryFn: ({ signal: a }) => ee({ signal: a, ...n }), ...e };
};
function zl(t) {
  const e = re(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const se = (t, e) => o.post(
  "/marketing/integrations",
  t,
  e
), ae = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return se(a, n);
  }, ...e };
}, $l = (t) => {
  const e = ae(t);
  return c(e);
}, oe = (t, e) => o.delete(
  `/marketing/integrations/${t}`,
  e
), ue = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { vendor: a } = s ?? {};
    return oe(a, n);
  }, ...e };
}, Vl = (t) => {
  const e = ue(t);
  return c(e);
}, ie = (t) => o.get(
  "/marketing/gtm_toolkit",
  t
), ce = () => ["/marketing/gtm_toolkit"], ye = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ce(), queryFn: ({ signal: a }) => ie({ signal: a, ...n }), ...e };
};
function Hl(t) {
  const e = ye(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const de = (t) => o.get(
  "/marketing/paragon_token",
  t
), _e = () => ["/marketing/paragon_token"], pe = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? _e(), queryFn: ({ signal: a }) => de({ signal: a, ...n }), ...e };
};
function Yl(t) {
  const e = pe(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const le = (t, e) => o.get(
  "/beneficial_owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), me = (t) => ["/beneficial_owners", ...t ? [t] : []], ge = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? me(t), queryFn: ({ signal: u }) => le(t, { signal: u, ...r }), ...n };
};
function jl(t, e) {
  const n = ge(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const qe = (t, e) => o.post(
  "/beneficial_owners",
  t,
  e
), fe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return qe(a, n);
  }, ...e };
}, Xl = (t) => {
  const e = fe(t);
  return c(e);
}, Oe = (t, e) => o.delete(
  `/beneficial_owners/${t}`,
  e
), he = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Oe(a, n);
  }, ...e };
}, Jl = (t) => {
  const e = he(t);
  return c(e);
}, Ke = (t, e, n) => o.patch(
  `/beneficial_owners/${t}`,
  e,
  n
), be = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ke(a, u, n);
  }, ...e };
}, Zl = (t) => {
  const e = be(t);
  return c(e);
}, ke = (t, e) => o.get(
  "/owner_is_beneficial_owner",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Me = (t) => ["/owner_is_beneficial_owner", ...t ? [t] : []], Pe = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Me(t), queryFn: ({ signal: u }) => ke(t, { signal: u, ...r }), ...n };
};
function tm(t, e) {
  const n = Pe(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const xe = (t, e) => o.patch(
  "/owner_is_beneficial_owner",
  t,
  e
), Be = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return xe(a, n);
  }, ...e };
}, em = (t) => {
  const e = Be(t);
  return c(e);
}, Re = (t, e) => o.get(
  "/banks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Fe = (t) => ["/banks", ...t ? [t] : []], Ee = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Fe(t), queryFn: ({ signal: u }) => Re(t, { signal: u, ...r }), ...n };
};
function nm(t, e) {
  const n = Ee(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ce = (t, e) => o.get(
  "/business_table_rows/product_agnostic",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ve = (t) => ["/business_table_rows/product_agnostic", ...t ? [t] : []], Ue = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ve(t), queryFn: ({ signal: u }) => Ce(t, { signal: u, ...r }), ...n };
};
function rm(t, e) {
  const n = Ue(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ne = (t, e) => o.get(
  "/business_table_rows/mca",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), De = (t) => ["/business_table_rows/mca", ...t ? [t] : []], Ae = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? De(t), queryFn: ({ signal: u }) => Ne(t, { signal: u, ...r }), ...n };
};
function sm(t, e) {
  const n = Ae(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Se = (t, e) => o.get(
  "/business_table_rows/loan",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ge = (t) => ["/business_table_rows/loan", ...t ? [t] : []], Ie = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ge(t), queryFn: ({ signal: u }) => Se(t, { signal: u, ...r }), ...n };
};
function am(t, e) {
  const n = Ie(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Te = (t, e) => o.get(
  "/business_table_rows/card",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), we = (t) => ["/business_table_rows/card", ...t ? [t] : []], Qe = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? we(t), queryFn: ({ signal: u }) => Te(t, { signal: u, ...r }), ...n };
};
function om(t, e) {
  const n = Qe(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Le = (t, e) => o.get(
  "/business/officers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), We = (t) => ["/business/officers", ...t ? [t] : []], ze = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? We(t), queryFn: ({ signal: u }) => Le(t, { signal: u, ...r }), ...n };
};
function um(t, e) {
  const n = ze(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const $e = (t, e) => o.post(
  "/business/officers",
  t,
  e
), Ve = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return $e(a, n);
  }, ...e };
}, im = (t) => {
  const e = Ve(t);
  return c(e);
}, He = (t, e) => o.delete(
  `/business/officers/${t}`,
  e
), Ye = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return He(a, n);
  }, ...e };
}, cm = (t) => {
  const e = Ye(t);
  return c(e);
}, je = (t, e, n) => o.patch(
  `/business/officers/${t}`,
  e,
  n
), Xe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return je(a, u, n);
  }, ...e };
}, ym = (t) => {
  const e = Xe(t);
  return c(e);
}, Je = (t) => o.post(
  "/dataingestion/oneschema/generate_jwt",
  void 0,
  t
), Ze = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Je(n), ...e };
}, dm = (t) => {
  const e = Ze(t);
  return c(e);
}, tn = (t, e) => o.get(
  "/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), en = (t) => ["/dataset_uploads", ...t ? [t] : []], nn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? en(t), queryFn: ({ signal: u }) => tn(t, { signal: u, ...r }), ...n };
};
function _m(t, e) {
  const n = nn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const rn = (t, e) => o.get(
  "/dataingestion/manual_uploads/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), sn = (t) => ["/dataingestion/manual_uploads/dataset_upload_validation_results", ...t ? [t] : []], an = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? sn(t), queryFn: ({ signal: u }) => rn(t, { signal: u, ...r }), ...n };
};
function pm(t, e) {
  const n = an(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const on = (t, e) => o.get(
  "/dataingestion/s3/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), un = (t) => ["/dataingestion/s3/dataset_uploads", ...t ? [t] : []], cn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? un(t), queryFn: ({ signal: u }) => on(t, { signal: u, ...r }), ...n };
};
function lm(t, e) {
  const n = cn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const yn = (t, e) => o.get(
  "/dataingestion/s3/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), dn = (t) => ["/dataingestion/s3/dataset_upload_validation_results", ...t ? [t] : []], _n = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? dn(t), queryFn: ({ signal: u }) => yn(t, { signal: u, ...r }), ...n };
};
function mm(t, e) {
  const n = _n(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const pn = (t, e) => o.get(
  "/dataingestion/s3/dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ln = (t) => ["/dataingestion/s3/dataset_integrations", ...t ? [t] : []], mn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ln(t), queryFn: ({ signal: u }) => pn(t, { signal: u, ...r }), ...n };
};
function gm(t, e) {
  const n = mn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const gn = (t, e) => o.post(
  "/dataingestion/s3/dataset_integrations",
  t,
  e
), qn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return gn(a, n);
  }, ...e };
}, qm = (t) => {
  const e = qn(t);
  return c(e);
}, fn = (t, e) => o.get(
  "/dataingestion/s3/org_dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), On = (t) => ["/dataingestion/s3/org_dataset_integrations", ...t ? [t] : []], hn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? On(t), queryFn: ({ signal: u }) => fn(t, { signal: u, ...r }), ...n };
};
function fm(t, e) {
  const n = hn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Kn = (t, e) => o.post(
  "/dataingestion/s3/org_dataset_integrations",
  t,
  e
), bn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Kn(a, n);
  }, ...e };
}, Om = (t) => {
  const e = bn(t);
  return c(e);
}, kn = (t, e) => o.get(
  "/dataingestion/s3/dataset_ingestion_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Mn = (t) => ["/dataingestion/s3/dataset_ingestion_configs", ...t ? [t] : []], Pn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Mn(t), queryFn: ({ signal: u }) => kn(t, { signal: u, ...r }), ...n };
};
function hm(t, e) {
  const n = Pn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const xn = (t, e) => o.put(
  "/dataingestion/s3/dataset_ingestion_configs",
  t,
  e
), Bn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return xn(a, n);
  }, ...e };
}, Km = (t) => {
  const e = Bn(t);
  return c(e);
}, Rn = (t, e) => o.post(
  "/dataingestion/s3/run_dataset_ingestion",
  t,
  e
), Fn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Rn(a, n);
  }, ...e };
}, bm = (t) => {
  const e = Fn(t);
  return c(e);
}, En = (t, e) => o.get(
  "/dataingestion/schema",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Cn = (t) => ["/dataingestion/schema", ...t ? [t] : []], vn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Cn(t), queryFn: ({ signal: u }) => En(t, { signal: u, ...r }), ...n };
};
function km(t, e) {
  const n = vn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Un = (t, e) => o.get(
  "/dataingestion/stripe_ingestions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Nn = (t) => ["/dataingestion/stripe_ingestions", ...t ? [t] : []], Dn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Nn(t), queryFn: ({ signal: u }) => Un(t, { signal: u, ...r }), ...n };
};
function Mm(t, e) {
  const n = Dn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const An = (t, e) => o.post(
  "/dataingestion/stripe_ingestions",
  t,
  e
), Sn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return An(a, n);
  }, ...e };
}, Pm = (t) => {
  const e = Sn(t);
  return c(e);
}, Gn = (t, e) => o.patch(
  "/dataingestion/stripe_ingestions",
  t,
  e
), In = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Gn(a, n);
  }, ...e };
}, xm = (t) => {
  const e = In(t);
  return c(e);
}, Tn = (t, e) => o.get(
  "/onboarding/business_requirements",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wn = (t) => ["/onboarding/business_requirements", ...t ? [t] : []], Qn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wn(t), queryFn: ({ signal: u }) => Tn(t, { signal: u, ...r }), ...n };
};
function Bm(t, e) {
  const n = Qn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ln = (t, e) => o.get(
  "/capital_product_prospective_terms_of_service",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wn = (t) => ["/capital_product_prospective_terms_of_service", ...t ? [t] : []], zn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wn(t), queryFn: ({ signal: u }) => Ln(t, { signal: u, ...r }), ...n };
};
function Rm(t, e) {
  const n = zn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const $n = (t, e) => o.get(
  "/capital_products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Vn = (t) => ["/capital_products", ...t ? [t] : []], Hn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Vn(t), queryFn: ({ signal: u }) => $n(t, { signal: u, ...r }), ...n };
};
function Fm(t, e) {
  const n = Hn(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Yn = (t, e) => o.post(
  "/capital_products",
  t,
  e
), jn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Yn(a, n);
  }, ...e };
}, Em = (t) => {
  const e = jn(t);
  return c(e);
}, Xn = (t, e, n) => o.patch(
  `/capital_products/${t}`,
  e,
  n
), Jn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Xn(a, u, n);
  }, ...e };
}, Cm = (t) => {
  const e = Jn(t);
  return c(e);
}, Zn = (t, e) => o.get(
  "/capital_product_offer_collections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), tr = (t) => ["/capital_product_offer_collections", ...t ? [t] : []], er = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? tr(t), queryFn: ({ signal: u }) => Zn(t, { signal: u, ...r }), ...n };
};
function vm(t, e) {
  const n = er(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const nr = (t, e) => o.post(
  "/capital_product_offer_collections",
  t,
  e
), rr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return nr(a, n);
  }, ...e };
}, Um = (t) => {
  const e = rr(t);
  return c(e);
}, sr = (t, e) => o.get(
  "/capital_product_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ar = (t) => ["/capital_product_activities", ...t ? [t] : []], or = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ar(t), queryFn: ({ signal: u }) => sr(t, { signal: u, ...r }), ...n };
};
function Nm(t, e) {
  const n = or(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ur = (t, e) => o.post(
  "/capital_product_applications",
  t,
  e
), ir = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ur(a, n);
  }, ...e };
}, Dm = (t) => {
  const e = ir(t);
  return c(e);
}, cr = (t, e) => o.post(
  "/net_top_up",
  t,
  e
), yr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return cr(a, n);
  }, ...e };
}, Am = (t) => {
  const e = yr(t);
  return c(e);
}, dr = (t, e) => o.get(
  "/capital_product_net_top_up_itemization",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _r = (t) => ["/capital_product_net_top_up_itemization", ...t ? [t] : []], pr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _r(t), queryFn: ({ signal: u }) => dr(t, { signal: u, ...r }), ...n };
};
function Sm(t, e) {
  const n = pr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const lr = (t, e) => o.get(
  "/capital_product_state_disclosure",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), mr = (t) => ["/capital_product_state_disclosure", ...t ? [t] : []], gr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? mr(t), queryFn: ({ signal: u }) => lr(t, { signal: u, ...r }), ...n };
};
function Gm(t, e) {
  const n = gr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const qr = (t) => o.get(
  "/benchmark_capital_products_ownerships",
  t
), fr = () => ["/benchmark_capital_products_ownerships"], Or = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? fr(), queryFn: ({ signal: a }) => qr({ signal: a, ...n }), ...e };
};
function Im(t) {
  const e = Or(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const hr = (t, e) => o.get(
  "/capital_product_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Kr = (t) => ["/capital_product_repayment_schedule", ...t ? [t] : []], br = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Kr(t), queryFn: ({ signal: u }) => hr(t, { signal: u, ...r }), ...n };
};
function Tm(t, e) {
  const n = br(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const kr = (t, e) => o.post(
  "/offered_capital_product_discount",
  t,
  e
), Mr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return kr(a, n);
  }, ...e };
}, wm = (t) => {
  const e = Mr(t);
  return c(e);
}, Pr = (t, e) => o.get(
  "/capital_product_intents",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xr = (t) => ["/capital_product_intents", ...t ? [t] : []], Br = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xr(t), queryFn: ({ signal: u }) => Pr(t, { signal: u, ...r }), ...n };
};
function Qm(t, e) {
  const n = Br(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Rr = (t, e) => o.post(
  "/capital_product_intents",
  t,
  e
), Fr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Rr(a, n);
  }, ...e };
}, Lm = (t) => {
  const e = Fr(t);
  return c(e);
}, Er = (t, e, n) => o.patch(
  `/capital_product_intents/${t}`,
  e,
  n
), Cr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Er(a, u, n);
  }, ...e };
}, Wm = (t) => {
  const e = Cr(t);
  return c(e);
}, vr = (t, e) => o.get(
  "/flex_loan_minimum_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ur = (t) => ["/flex_loan_minimum_repayment_schedule", ...t ? [t] : []], Nr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ur(t), queryFn: ({ signal: u }) => vr(t, { signal: u, ...r }), ...n };
};
function zm(t, e) {
  const n = Nr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Dr = (t, e) => o.post(
  "/trigger_run_celtics_checklist",
  t,
  e
), Ar = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Dr(a, n);
  }, ...e };
}, $m = (t) => {
  const e = Ar(t);
  return c(e);
}, Sr = (t, e) => o.get(
  "/run_ofac_screening_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Gr = (t) => ["/run_ofac_screening_results", ...t ? [t] : []], Ir = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Gr(t), queryFn: ({ signal: u }) => Sr(t, { signal: u, ...r }), ...n };
};
function Vm(t, e) {
  const n = Ir(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Tr = (t, e, n) => o.patch(
  `/clear_watchlist_hits/${t}`,
  e,
  n
), wr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Tr(a, u, n);
  }, ...e };
}, Hm = (t) => {
  const e = wr(t);
  return c(e);
}, Qr = (t, e) => o.get(
  "/term_loan_prospective_repayments",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Lr = (t) => ["/term_loan_prospective_repayments", ...t ? [t] : []], Wr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Lr(t), queryFn: ({ signal: u }) => Qr(t, { signal: u, ...r }), ...n };
};
function Ym(t, e) {
  const n = Wr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const zr = (t, e) => o.get(
  "/funding/bank_account_info",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $r = (t) => ["/funding/bank_account_info", ...t ? [t] : []], Vr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $r(t), queryFn: ({ signal: u }) => zr(t, { signal: u, ...r }), ...n };
};
function jm(t, e) {
  const n = Vr(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Hr = (t, e) => o.post(
  "/funding/bank_account_info",
  t,
  e
), Yr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Hr(a, n);
  }, ...e };
}, Xm = (t) => {
  const e = Yr(t);
  return c(e);
}, jr = (t, e, n) => o.patch(
  `/funding/bank_account_info/${t}`,
  e,
  n
), Xr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return jr(a, u, n);
  }, ...e };
}, Jm = (t) => {
  const e = Xr(t);
  return c(e);
}, Jr = (t, e) => o.get(
  "/funding/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Zr = (t) => ["/funding/accounts", ...t ? [t] : []], ts = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Zr(t), queryFn: ({ signal: u }) => Jr(t, { signal: u, ...r }), ...n };
};
function Zm(t, e) {
  const n = ts(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const es = (t, e) => o.post(
  "/funding/accounts",
  t,
  e
), ns = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return es(a, n);
  }, ...e };
}, tg = (t) => {
  const e = ns(t);
  return c(e);
}, rs = (t, e) => o.get(
  "/funding/plaid_linkage_with_investigation",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ss = (t) => ["/funding/plaid_linkage_with_investigation", ...t ? [t] : []], as = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ss(t), queryFn: ({ signal: u }) => rs(t, { signal: u, ...r }), ...n };
};
function eg(t, e) {
  const n = as(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const os = (t, e) => o.post(
  "/funding/plaid_linkage_with_investigation",
  t,
  e
), us = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return os(a, n);
  }, ...e };
}, ng = (t) => {
  const e = us(t);
  return c(e);
}, is = (t, e, n) => o.patch(
  `/persona_fallback_inquiries/${t}`,
  e,
  n
), cs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return is(a, u, n);
  }, ...e };
}, rg = (t) => {
  const e = cs(t);
  return c(e);
}, ys = (t, e) => o.get(
  "/onboarding/states",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ds = (t) => ["/onboarding/states", ...t ? [t] : []], _s = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ds(t), queryFn: ({ signal: u }) => ys(t, { signal: u, ...r }), ...n };
};
function sg(t, e) {
  const n = _s(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ps = (t, e) => o.get(
  "/onboarding/info_needed",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ls = (t) => ["/onboarding/info_needed", ...t ? [t] : []], ms = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ls(t), queryFn: ({ signal: u }) => ps(t, { signal: u, ...r }), ...n };
};
function ag(t, e) {
  const n = ms(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const gs = (t, e) => o.get(
  "/business_identities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), qs = (t) => ["/business_identities", ...t ? [t] : []], fs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qs(t), queryFn: ({ signal: u }) => gs(t, { signal: u, ...r }), ...n };
};
function og(t, e) {
  const n = fs(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Os = (t, e, n) => o.patch(
  `/business_identities/${t}`,
  e,
  n
), hs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Os(a, u, n);
  }, ...e };
}, ug = (t) => {
  const e = hs(t);
  return c(e);
}, Ks = (t, e) => o.get(
  "/owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), bs = (t) => ["/owners", ...t ? [t] : []], ks = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? bs(t), queryFn: ({ signal: u }) => Ks(t, { signal: u, ...r }), ...n };
};
function ig(t, e) {
  const n = ks(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ms = (t, e, n) => o.patch(
  `/owners/${t}`,
  e,
  n
), Ps = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ms(a, u, n);
  }, ...e };
}, cg = (t) => {
  const e = Ps(t);
  return c(e);
}, xs = (t, e) => o.post(
  "/business",
  t,
  e
), Bs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return xs(a, n);
  }, ...e };
}, yg = (t) => {
  const e = Bs(t);
  return c(e);
}, Rs = (t, e) => o.post(
  "/merchant_bank/business",
  t,
  e
), Fs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Rs(a, n);
  }, ...e };
}, dg = (t) => {
  const e = Fs(t);
  return c(e);
}, Es = (t) => o.post(
  "/amazon_redirect_url",
  void 0,
  t
), Cs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Es(n), ...e };
}, _g = (t) => {
  const e = Cs(t);
  return c(e);
}, vs = (t, e) => o.post(
  "/spd",
  t,
  e
), Us = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return vs(a, n);
  }, ...e };
}, pg = (t) => {
  const e = Us(t);
  return c(e);
}, Ns = (t, e) => o.post(
  "/capital_product_business",
  t,
  e
), Ds = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ns(a, n);
  }, ...e };
}, lg = (t) => {
  const e = Ds(t);
  return c(e);
}, As = (t, e) => o.patch(
  "/flex_loan_age",
  t,
  e
), Ss = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return As(a, n);
  }, ...e };
}, mg = (t) => {
  const e = Ss(t);
  return c(e);
}, Gs = (t, e) => o.patch(
  "/flex_loan_ownership",
  t,
  e
), Is = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Gs(a, n);
  }, ...e };
}, gg = (t) => {
  const e = Is(t);
  return c(e);
}, Ts = (t, e) => o.patch(
  "/flex_loan_fund",
  t,
  e
), ws = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ts(a, n);
  }, ...e };
}, qg = (t) => {
  const e = ws(t);
  return c(e);
}, Qs = (t, e) => o.post(
  "/setup_amazon_top_up",
  void 0,
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ls = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { params: a } = s ?? {};
    return Qs(a, n);
  }, ...e };
}, fg = (t) => {
  const e = Ls(t);
  return c(e);
}, Ws = (t, e) => o.get(
  "/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zs = (t) => ["/cards", ...t ? [t] : []], $s = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zs(t), queryFn: ({ signal: u }) => Ws(t, { signal: u, ...r }), ...n };
};
function Og(t, e) {
  const n = $s(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Vs = (t, e) => o.post(
  "/cards",
  t,
  e
), Hs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Vs(a, n);
  }, ...e };
}, hg = (t) => {
  const e = Hs(t);
  return c(e);
}, Ys = (t, e) => o.get(
  "/businesses/products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), js = (t) => ["/businesses/products", ...t ? [t] : []], Xs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? js(t), queryFn: ({ signal: u }) => Ys(t, { signal: u, ...r }), ...n };
};
function Kg(t, e) {
  const n = Xs(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Js = (t, e) => o.post(
  "/business/auth_link",
  t,
  e
), Zs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Js(a, n);
  }, ...e };
}, bg = (t) => {
  const e = Zs(t);
  return c(e);
}, ta = (t, e) => o.get(
  "/document_upload/group",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ea = (t) => ["/document_upload/group", ...t ? [t] : []], na = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ea(t), queryFn: ({ signal: u }) => ta(t, { signal: u, ...r }), ...n };
};
function kg(t, e) {
  const n = na(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ra = (t, e) => o.post(
  "/document_upload/group",
  t,
  e
), sa = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ra(a, n);
  }, ...e };
}, Mg = (t) => {
  const e = sa(t);
  return c(e);
}, aa = (t, e) => o.get(
  "/document_upload/list",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), oa = (t) => ["/document_upload/list", ...t ? [t] : []], ua = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? oa(t), queryFn: ({ signal: u }) => aa(t, { signal: u, ...r }), ...n };
};
function Pg(t, e) {
  const n = ua(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ia = (t, e) => o.get(
  "/restriction",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ca = (t) => ["/restriction", ...t ? [t] : []], ya = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ca(t), queryFn: ({ signal: u }) => ia(t, { signal: u, ...r }), ...n };
};
function xg(t, e) {
  const n = ya(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const da = (t, e) => o.post(
  "/restriction",
  t,
  e
), _a = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return da(a, n);
  }, ...e };
}, Bg = (t) => {
  const e = _a(t);
  return c(e);
}, pa = (t, e) => o.get(
  "/restrictions/details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), la = (t) => ["/restrictions/details", ...t ? [t] : []], ma = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? la(t), queryFn: ({ signal: u }) => pa(t, { signal: u, ...r }), ...n };
};
function Rg(t, e) {
  const n = ma(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ga = (t, e) => o.get(
  "/liens",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), qa = (t) => ["/liens", ...t ? [t] : []], fa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qa(t), queryFn: ({ signal: u }) => ga(t, { signal: u, ...r }), ...n };
};
function Fg(t, e) {
  const n = fa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Oa = (t, e, n) => o.patch(
  `/liens/${t}`,
  e,
  n
), ha = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Oa(a, u, n);
  }, ...e };
}, Eg = (t) => {
  const e = ha(t);
  return c(e);
}, Ka = (t, e) => o.post(
  "/opt_ins/trigger",
  t,
  e
), ba = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ka(a, n);
  }, ...e };
}, Cg = (t) => {
  const e = ba(t);
  return c(e);
}, ka = (t, e) => o.get(
  "/businesses/daily_sales_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ma = (t) => ["/businesses/daily_sales_records", ...t ? [t] : []], Pa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ma(t), queryFn: ({ signal: u }) => ka(t, { signal: u, ...r }), ...n };
};
function vg(t, e) {
  const n = Pa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const xa = (t, e) => o.get(
  "/parafinder/businesses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ba = (t) => ["/parafinder/businesses", ...t ? [t] : []], Ra = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ba(t), queryFn: ({ signal: u }) => xa(t, { signal: u, ...r }), ...n };
};
function Ug(t, e) {
  const n = Ra(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fa = (t) => o.get(
  "/parafinder/business_types",
  t
), Ea = () => ["/parafinder/business_types"], Ca = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ea(), queryFn: ({ signal: a }) => Fa({ signal: a, ...n }), ...e };
};
function Ng(t) {
  const e = Ca(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const va = (t, e) => o.get(
  "/parafinder/summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ua = (t) => ["/parafinder/summary", ...t ? [t] : []], Na = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ua(t), queryFn: ({ signal: u }) => va(t, { signal: u, ...r }), ...n };
};
function Dg(t, e) {
  const n = Na(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Da = (t, e) => o.get(
  "/parafinderV2/capital_info_summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Aa = (t) => ["/parafinderV2/capital_info_summary", ...t ? [t] : []], Sa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Aa(t), queryFn: ({ signal: u }) => Da(t, { signal: u, ...r }), ...n };
};
function Ag(t, e) {
  const n = Sa(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ga = (t, e) => o.get(
  `/merchant_bank/accounts/${t}`,
  e
), Ia = (t) => [`/merchant_bank/accounts/${t}`], Ta = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ia(t), queryFn: ({ signal: u }) => Ga(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Sg(t, e) {
  const n = Ta(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const wa = (t, e) => o.get(
  `/merchant_bank/accounts/${t}/balances`,
  e
), Qa = (t) => [`/merchant_bank/accounts/${t}/balances`], La = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qa(t), queryFn: ({ signal: u }) => wa(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Gg(t, e) {
  const n = La(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Wa = (t, e) => o.get(
  "/merchant_bank/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), za = (t) => ["/merchant_bank/accounts", ...t ? [t] : []], $a = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? za(t), queryFn: ({ signal: u }) => Wa(t, { signal: u, ...r }), ...n };
};
function Ig(t, e) {
  const n = $a(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Va = (t, e) => o.post(
  "/merchant_bank/accounts",
  t,
  e
), Ha = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Va(a, n);
  }, ...e };
}, Tg = (t) => {
  const e = Ha(t);
  return c(e);
}, Ya = (t, e) => o.post(
  "/merchant_bank/inner_transfers",
  t,
  e
), ja = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ya(a, n);
  }, ...e };
}, wg = (t) => {
  const e = ja(t);
  return c(e);
}, Xa = (t, e, n) => o.get(
  `/merchant_bank/transactions/${t}`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Ja = (t, e) => [`/merchant_bank/transactions/${t}`, ...e ? [e] : []], Za = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Ja(t, e), queryFn: ({ signal: y }) => Xa(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function Qg(t, e, n) {
  const r = Za(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const to = (t, e) => o.get(
  "/merchant_bank/transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), eo = (t) => ["/merchant_bank/transactions", ...t ? [t] : []], no = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? eo(t), queryFn: ({ signal: u }) => to(t, { signal: u, ...r }), ...n };
};
function Lg(t, e) {
  const n = no(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ro = (t) => o.get(
  "/merchant_bank/transfer_fees",
  t
), so = () => ["/merchant_bank/transfer_fees"], ao = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? so(), queryFn: ({ signal: a }) => ro({ signal: a, ...n }), ...e };
};
function Wg(t) {
  const e = ao(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const oo = (t, e) => o.get(
  "/merchant_bank/transfer_limits",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), uo = (t) => ["/merchant_bank/transfer_limits", ...t ? [t] : []], io = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? uo(t), queryFn: ({ signal: u }) => oo(t, { signal: u, ...r }), ...n };
};
function zg(t, e) {
  const n = io(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const co = (t) => o.get(
  "/merchant_bank/interest_details",
  t
), yo = () => ["/merchant_bank/interest_details"], _o = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? yo(), queryFn: ({ signal: a }) => co({ signal: a, ...n }), ...e };
};
function $g(t) {
  const e = _o(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const po = (t, e) => o.get(
  "/merchant_bank/recipients",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), lo = (t) => ["/merchant_bank/recipients", ...t ? [t] : []], mo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? lo(t), queryFn: ({ signal: u }) => po(t, { signal: u, ...r }), ...n };
};
function Vg(t, e) {
  const n = mo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const go = (t, e) => o.post(
  "/merchant_bank/recipients",
  t,
  e
), qo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return go(a, n);
  }, ...e };
}, Hg = (t) => {
  const e = qo(t);
  return c(e);
}, fo = (t, e) => o.get(
  `/merchant_bank/recipients/${t}`,
  e
), Oo = (t) => [`/merchant_bank/recipients/${t}`], ho = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Oo(t), queryFn: ({ signal: u }) => fo(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Yg(t, e) {
  const n = ho(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ko = (t, e) => o.delete(
  `/merchant_bank/recipients/${t}`,
  e
), bo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Ko(a, n);
  }, ...e };
}, jg = (t) => {
  const e = bo(t);
  return c(e);
}, ko = (t, e, n) => o.patch(
  `/merchant_bank/recipients/${t}`,
  e,
  n
), Mo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return ko(a, u, n);
  }, ...e };
}, Xg = (t) => {
  const e = Mo(t);
  return c(e);
}, Po = (t, e, n) => o.post(
  `/merchant_bank/recipients/${t}/payment_rails`,
  e,
  n
), xo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Po(a, u, n);
  }, ...e };
}, Jg = (t) => {
  const e = xo(t);
  return c(e);
}, Bo = (t, e) => o.post(
  "/merchant_bank/recipient_transfers",
  t,
  e
), Ro = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Bo(a, n);
  }, ...e };
}, Zg = (t) => {
  const e = Ro(t);
  return c(e);
}, Fo = (t, e, n) => o.patch(
  `/merchant_bank/recipient_transfers/${t}`,
  e,
  n
), Eo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Fo(a, u, n);
  }, ...e };
}, tq = (t) => {
  const e = Eo(t);
  return c(e);
}, Co = (t, e) => o.get(
  "/merchant_bank/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), vo = (t) => ["/merchant_bank/cards", ...t ? [t] : []], Uo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? vo(t), queryFn: ({ signal: u }) => Co(t, { signal: u, ...r }), ...n };
};
function eq(t, e) {
  const n = Uo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const No = (t, e) => o.post(
  "/merchant_bank/cards",
  t,
  e
), Do = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return No(a, n);
  }, ...e };
}, nq = (t) => {
  const e = Do(t);
  return c(e);
}, Ao = (t, e, n) => o.patch(
  `/merchant_bank/card/cardholders/${t}`,
  e,
  n
), So = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ao(a, u, n);
  }, ...e };
}, rq = (t) => {
  const e = So(t);
  return c(e);
}, Go = (t, e) => o.get(
  "/merchant_bank/card/cardholders",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Io = (t) => ["/merchant_bank/card/cardholders", ...t ? [t] : []], To = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Io(t), queryFn: ({ signal: u }) => Go(t, { signal: u, ...r }), ...n };
};
function sq(t, e) {
  const n = To(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const wo = (t, e) => o.post(
  "/merchant_bank/card/activate",
  t,
  e
), Qo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return wo(a, n);
  }, ...e };
}, aq = (t) => {
  const e = Qo(t);
  return c(e);
}, Lo = (t, e) => o.get(
  "/merchant_bank/card/access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wo = (t) => ["/merchant_bank/card/access_token", ...t ? [t] : []], zo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wo(t), queryFn: ({ signal: u }) => Lo(t, { signal: u, ...r }), ...n };
};
function oq(t, e) {
  const n = zo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const $o = (t, e) => o.post(
  "/merchant_bank/card/apple_pay",
  t,
  e
), Vo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return $o(a, n);
  }, ...e };
}, uq = (t) => {
  const e = Vo(t);
  return c(e);
}, Ho = (t, e) => o.get(
  "/merchant_bank/card/cardholder_access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Yo = (t) => ["/merchant_bank/card/cardholder_access_token", ...t ? [t] : []], jo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Yo(t), queryFn: ({ signal: u }) => Ho(t, { signal: u, ...r }), ...n };
};
function iq(t, e) {
  const n = jo(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Xo = (t, e) => o.post(
  "/merchant_bank/card/state_transition",
  t,
  e
), Jo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Xo(a, n);
  }, ...e };
}, cq = (t) => {
  const e = Jo(t);
  return c(e);
}, Zo = (t, e) => o.post(
  "/merchant_bank/card/google_pay",
  t,
  e
), tu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Zo(a, n);
  }, ...e };
}, yq = (t) => {
  const e = tu(t);
  return c(e);
}, eu = (t, e) => o.get(
  "/merchant_bank/card/disputes",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), nu = (t) => ["/merchant_bank/card/disputes", ...t ? [t] : []], ru = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? nu(t), queryFn: ({ signal: u }) => eu(t, { signal: u, ...r }), ...n };
};
function dq(t, e) {
  const n = ru(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const su = (t, e) => o.post(
  "/merchant_bank/card/disputes",
  t,
  e
), au = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return su(a, n);
  }, ...e };
}, _q = (t) => {
  const e = au(t);
  return c(e);
}, ou = (t, e) => o.post(
  "/merchant_bank/card/dispute/state_transition",
  t,
  e
), uu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ou(a, n);
  }, ...e };
}, pq = (t) => {
  const e = uu(t);
  return c(e);
}, iu = (t, e) => o.post(
  "/merchant_bank/card/dispute/chargeback_credit",
  t,
  e
), cu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return iu(a, n);
  }, ...e };
}, lq = (t) => {
  const e = cu(t);
  return c(e);
}, yu = (t, e) => o.get(
  "/merchant_bank/external_accounts/plaid_link_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), du = (t) => ["/merchant_bank/external_accounts/plaid_link_token", ...t ? [t] : []], _u = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? du(t), queryFn: ({ signal: u }) => yu(t, { signal: u, ...r }), ...n };
};
function mq(t, e) {
  const n = _u(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const pu = (t, e) => o.get(
  `/merchant_bank/external_accounts/${t}/plaid_link_token`,
  e
), lu = (t) => [`/merchant_bank/external_accounts/${t}/plaid_link_token`], mu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? lu(t), queryFn: ({ signal: u }) => pu(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function gq(t, e) {
  const n = mu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const gu = (t, e) => o.get(
  "/merchant_bank/external_accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), qu = (t) => ["/merchant_bank/external_accounts", ...t ? [t] : []], fu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? qu(t), queryFn: ({ signal: u }) => gu(t, { signal: u, ...r }), ...n };
};
function qq(t, e) {
  const n = fu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ou = (t, e) => o.post(
  "/merchant_bank/external_accounts",
  t,
  e
), hu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ou(a, n);
  }, ...e };
}, fq = (t) => {
  const e = hu(t);
  return c(e);
}, Ku = (t, e) => o.get(
  `/merchant_bank/external_accounts/${t}`,
  e
), bu = (t) => [`/merchant_bank/external_accounts/${t}`], ku = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? bu(t), queryFn: ({ signal: u }) => Ku(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Oq(t, e) {
  const n = ku(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Mu = (t, e) => o.delete(
  `/merchant_bank/external_accounts/${t}`,
  e
), Pu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Mu(a, n);
  }, ...e };
}, hq = (t) => {
  const e = Pu(t);
  return c(e);
}, xu = (t, e, n) => o.patch(
  `/merchant_bank/external_accounts/${t}`,
  e,
  n
), Bu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return xu(a, u, n);
  }, ...e };
}, Kq = (t) => {
  const e = Bu(t);
  return c(e);
}, Ru = (t, e) => o.post(
  "/merchant_bank/external_bank_transfers",
  t,
  e
), Fu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ru(a, n);
  }, ...e };
}, bq = (t) => {
  const e = Fu(t);
  return c(e);
}, Eu = (t, e, n) => o.patch(
  `/merchant_bank/external_bank_transfers/${t}`,
  e,
  n
), Cu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Eu(a, u, n);
  }, ...e };
}, kq = (t) => {
  const e = Cu(t);
  return c(e);
}, vu = (t, e) => o.get(
  "/debts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Uu = (t) => ["/debts", ...t ? [t] : []], Nu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Uu(t), queryFn: ({ signal: u }) => vu(t, { signal: u, ...r }), ...n };
};
function Mq(t, e) {
  const n = Nu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Du = (t, e) => o.post(
  "/debts",
  t,
  e
), Au = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Du(a, n);
  }, ...e };
}, Pq = (t) => {
  const e = Au(t);
  return c(e);
}, Su = (t, e) => o.post(
  "/debts/check_complete",
  t,
  e
), Gu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Su(a, n);
  }, ...e };
}, xq = (t) => {
  const e = Gu(t);
  return c(e);
}, Iu = (t, e) => o.get(
  "/debt_checks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Tu = (t) => ["/debt_checks", ...t ? [t] : []], wu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Tu(t), queryFn: ({ signal: u }) => Iu(t, { signal: u, ...r }), ...n };
};
function Bq(t, e) {
  const n = wu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Qu = (t, e) => o.post(
  "/card/close_account",
  t,
  e
), Lu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Qu(a, n);
  }, ...e };
}, Rq = (t) => {
  const e = Lu(t);
  return c(e);
}, Wu = (t, e) => o.get(
  "/financial_reviews",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zu = (t) => ["/financial_reviews", ...t ? [t] : []], $u = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zu(t), queryFn: ({ signal: u }) => Wu(t, { signal: u, ...r }), ...n };
};
function Fq(t, e) {
  const n = $u(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Vu = (t, e) => o.post(
  "/financial_reviews",
  t,
  e
), Hu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Vu(a, n);
  }, ...e };
}, Eq = (t) => {
  const e = Hu(t);
  return c(e);
}, Yu = (t, e) => o.post(
  "/tax_records",
  t,
  e
), ju = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Yu(a, n);
  }, ...e };
}, Cq = (t) => {
  const e = ju(t);
  return c(e);
}, Xu = (t, e) => o.get(
  "/officer_subsidiary_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ju = (t) => ["/officer_subsidiary_records", ...t ? [t] : []], Zu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ju(t), queryFn: ({ signal: u }) => Xu(t, { signal: u, ...r }), ...n };
};
function vq(t, e) {
  const n = Zu(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ti = (t, e) => o.post(
  "/officer_subsidiary_records",
  t,
  e
), ei = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ti(a, n);
  }, ...e };
}, Uq = (t) => {
  const e = ei(t);
  return c(e);
}, ni = (t) => o.get(
  "/analytics/cube_token",
  t
), ri = () => ["/analytics/cube_token"], si = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ri(), queryFn: ({ signal: a }) => ni({ signal: a, ...n }), ...e };
};
function Nq(t) {
  const e = si(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const ai = (t, e) => o.get(
  "/logs/api_request",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), oi = (t) => ["/logs/api_request", ...t ? [t] : []], ui = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? oi(t), queryFn: ({ signal: u }) => ai(t, { signal: u, ...r }), ...n };
};
function Dq(t, e) {
  const n = ui(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ii = (t) => o.get(
  "/marketing/offer_csvs",
  t
), ci = () => ["/marketing/offer_csvs"], yi = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ci(), queryFn: ({ signal: a }) => ii({ signal: a, ...n }), ...e };
};
function Aq(t) {
  const e = yi(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const di = (t) => o.post(
  "/marketing/offer_csvs/generate",
  void 0,
  t
), _i = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => di(n), ...e };
}, Sq = (t) => {
  const e = _i(t);
  return c(e);
}, pi = (t, e) => o.get(
  "/businesses/notifications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), li = (t) => ["/businesses/notifications", ...t ? [t] : []], mi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? li(t), queryFn: ({ signal: u }) => pi(t, { signal: u, ...r }), ...n };
};
function Gq(t, e) {
  const n = mi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const gi = (t, e) => o.post(
  "/businesses/notifications",
  t,
  e
), qi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return gi(a, n);
  }, ...e };
}, Iq = (t) => {
  const e = qi(t);
  return c(e);
}, fi = (t, e) => o.post(
  "/terms_of_service_mark_shown",
  t,
  e
), Oi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return fi(a, n);
  }, ...e };
}, Tq = (t) => {
  const e = Oi(t);
  return c(e);
}, hi = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/applications`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Ki = (t, e) => [`/partner_dashboard/business_details_page/${t}/applications`, ...e ? [e] : []], bi = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Ki(t, e), queryFn: ({ signal: y }) => hi(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function wq(t, e, n) {
  const r = bi(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const ki = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/bank_info`,
  e
), Mi = (t) => [`/partner_dashboard/business_details_page/${t}/bank_info`], Pi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Mi(t), queryFn: ({ signal: u }) => ki(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Qq(t, e) {
  const n = Pi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const xi = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/business_info`,
  e
), Bi = (t) => [`/partner_dashboard/business_details_page/${t}/business_info`], Ri = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Bi(t), queryFn: ({ signal: u }) => xi(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Lq(t, e) {
  const n = Ri(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fi = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/funded_products`,
  e
), Ei = (t) => [`/partner_dashboard/business_details_page/${t}/funded_products`], Ci = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ei(t), queryFn: ({ signal: u }) => Fi(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Wq(t, e) {
  const n = Ci(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const vi = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/funded_product_details`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Ui = (t, e) => [`/partner_dashboard/business_details_page/${t}/funded_product_details`, ...e ? [e] : []], Ni = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Ui(t, e), queryFn: ({ signal: y }) => vi(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function zq(t, e, n) {
  const r = Ni(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Di = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/payment_progress`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Ai = (t, e) => [`/partner_dashboard/business_details_page/${t}/payment_progress`, ...e ? [e] : []], Si = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Ai(t, e), queryFn: ({ signal: y }) => Di(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function $q(t, e, n) {
  const r = Si(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Gi = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/offers`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Ii = (t, e) => [`/partner_dashboard/business_details_page/${t}/offers`, ...e ? [e] : []], Ti = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Ii(t, e), queryFn: ({ signal: y }) => Gi(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function Vq(t, e, n) {
  const r = Ti(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const wi = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/owner_info`,
  e
), Qi = (t) => [`/partner_dashboard/business_details_page/${t}/owner_info`], Li = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qi(t), queryFn: ({ signal: u }) => wi(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Hq(t, e) {
  const n = Li(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Wi = (t, e) => o.get(
  "/partner_dashboard/businesses_page/businesses_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zi = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows/count", ...t ? [t] : []], $i = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zi(t), queryFn: ({ signal: u }) => Wi(t, { signal: u, ...r }), ...n };
};
function Yq(t, e) {
  const n = $i(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Vi = (t, e) => o.get(
  "/partner_dashboard/businesses_page/businesses_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Hi = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows", ...t ? [t] : []], Yi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Hi(t), queryFn: ({ signal: u }) => Vi(t, { signal: u, ...r }), ...n };
};
function jq(t, e) {
  const n = Yi(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ji = (t, e) => o.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xi = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows/count", ...t ? [t] : []], Ji = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xi(t), queryFn: ({ signal: u }) => ji(t, { signal: u, ...r }), ...n };
};
function Xq(t, e) {
  const n = Ji(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Zi = (t, e) => o.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), tc = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows", ...t ? [t] : []], ec = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? tc(t), queryFn: ({ signal: u }) => Zi(t, { signal: u, ...r }), ...n };
};
function Jq(t, e) {
  const n = ec(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const nc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/applications_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), rc = (t) => ["/partner_dashboard/capital_tab/applications_table_rows/count", ...t ? [t] : []], sc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? rc(t), queryFn: ({ signal: u }) => nc(t, { signal: u, ...r }), ...n };
};
function Zq(t, e) {
  const n = sc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ac = (t, e) => o.get(
  "/partner_dashboard/capital_tab/applications_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), oc = (t) => ["/partner_dashboard/capital_tab/applications_table_rows", ...t ? [t] : []], uc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? oc(t), queryFn: ({ signal: u }) => ac(t, { signal: u, ...r }), ...n };
};
function tf(t, e) {
  const n = uc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ic = (t, e) => o.get(
  "/partner_dashboard/capital_tab/offers_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), cc = (t) => ["/partner_dashboard/capital_tab/offers_table_rows/count", ...t ? [t] : []], yc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? cc(t), queryFn: ({ signal: u }) => ic(t, { signal: u, ...r }), ...n };
};
function ef(t, e) {
  const n = yc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const dc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/offers_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _c = (t) => ["/partner_dashboard/capital_tab/offers_table_rows", ...t ? [t] : []], pc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _c(t), queryFn: ({ signal: u }) => dc(t, { signal: u, ...r }), ...n };
};
function nf(t, e) {
  const n = pc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const lc = (t) => o.get(
  "/partner_dashboard/platform_tab/partner_support_info",
  t
), mc = () => ["/partner_dashboard/platform_tab/partner_support_info"], gc = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? mc(), queryFn: ({ signal: a }) => lc({ signal: a, ...n }), ...e };
};
function rf(t) {
  const e = gc(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const qc = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/offer_url`,
  e
), fc = (t) => [`/partner_dashboard/business_details_page/${t}/offer_url`], Oc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? fc(t), queryFn: ({ signal: u }) => qc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function sf(t, e) {
  const n = Oc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const hc = (t) => o.get(
  "/partner_dashboard/crm/integration",
  t
), Kc = () => ["/partner_dashboard/crm/integration"], bc = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Kc(), queryFn: ({ signal: a }) => hc({ signal: a, ...n }), ...e };
};
function af(t) {
  const e = bc(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const kc = (t) => o.get(
  "/partner_dashboard/valid_object_id_prefixes",
  t
), Mc = () => ["/partner_dashboard/valid_object_id_prefixes"], Pc = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Mc(), queryFn: ({ signal: a }) => kc({ signal: a, ...n }), ...e };
};
function of(t) {
  const e = Pc(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const xc = (t, e) => o.get(
  "/partner_dashboard/webhook_logs/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Bc = (t) => ["/partner_dashboard/webhook_logs/events", ...t ? [t] : []], Rc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Bc(t), queryFn: ({ signal: u }) => xc(t, { signal: u, ...r }), ...n };
};
function uf(t, e) {
  const n = Rc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Fc = (t, e) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}`,
  e
), Ec = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}`], Cc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ec(t), queryFn: ({ signal: u }) => Fc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function cf(t, e) {
  const n = Cc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const vc = (t, e, n) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Uc = (t, e) => [`/partner_dashboard/webhook_logs/event_info/${t}/attempts`, ...e ? [e] : []], Nc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Uc(t, e), queryFn: ({ signal: y }) => vc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function yf(t, e, n) {
  const r = Nc(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Dc = (t, e) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`,
  e
), Ac = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`], Sc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ac(t), queryFn: ({ signal: u }) => Dc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function df(t, e) {
  const n = Sc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Gc = (t, e) => o.post(
  "/partner_dashboard/webhook_logs/resend",
  t,
  e
), Ic = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Gc(a, n);
  }, ...e };
}, _f = (t) => {
  const e = Ic(t);
  return c(e);
}, Tc = (t, e) => o.post(
  "/partner_dashboard/webhook_logs/resend_event",
  t,
  e
), wc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Tc(a, n);
  }, ...e };
}, pf = (t) => {
  const e = wc(t);
  return c(e);
}, Qc = (t, e) => o.get(
  "/partner_dashboard/crm/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Lc = (t) => ["/partner_dashboard/crm/events", ...t ? [t] : []], Wc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Lc(t), queryFn: ({ signal: u }) => Qc(t, { signal: u, ...r }), ...n };
};
function lf(t, e) {
  const n = Wc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const zc = (t, e, n) => o.get(
  `/partner_dashboard/crm/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), $c = (t, e) => [`/partner_dashboard/crm/event_info/${t}/attempts`, ...e ? [e] : []], Vc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? $c(t, e), queryFn: ({ signal: y }) => zc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function mf(t, e, n) {
  const r = Vc(t, e, n), s = i(r);
  return s.queryKey = r.queryKey, s;
}
const Hc = (t, e) => o.post(
  "/partner_dashboard/crm/resend",
  t,
  e
), Yc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Hc(a, n);
  }, ...e };
}, gf = (t) => {
  const e = Yc(t);
  return c(e);
}, jc = (t, e) => o.get(
  `/partner_dashboard/crm/event_info/${t}`,
  e
), Xc = (t) => [`/partner_dashboard/crm/event_info/${t}`], Jc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xc(t), queryFn: ({ signal: u }) => jc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function qf(t, e) {
  const n = Jc(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Zc = (t) => o.post(
  "/partner_dashboard/crm/trigger_sync",
  void 0,
  t
), ty = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Zc(n), ...e };
}, ff = (t) => {
  const e = ty(t);
  return c(e);
}, ey = (t) => o.get(
  "/partner_dashboard/crm/sync",
  t
), ny = () => ["/partner_dashboard/crm/sync"], ry = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ny(), queryFn: ({ signal: a }) => ey({ signal: a, ...n }), ...e };
};
function Of(t) {
  const e = ry(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const sy = (t) => o.post(
  "/partner_dashboard/crm/sync",
  void 0,
  t
), ay = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => sy(n), ...e };
}, hf = (t) => {
  const e = ay(t);
  return c(e);
}, oy = (t, e) => o.get(
  "/partner_dashboard/webhook_endpoints",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), uy = (t) => ["/partner_dashboard/webhook_endpoints", ...t ? [t] : []], iy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? uy(t), queryFn: ({ signal: u }) => oy(t, { signal: u, ...r }), ...n };
};
function Kf(t, e) {
  const n = iy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const cy = (t, e) => o.post(
  "/partner_dashboard/webhook_endpoints",
  t,
  e
), yy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return cy(a, n);
  }, ...e };
}, bf = (t) => {
  const e = yy(t);
  return c(e);
}, dy = (t, e) => o.delete(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e
), _y = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return dy(a, n);
  }, ...e };
}, kf = (t) => {
  const e = _y(t);
  return c(e);
}, py = (t, e, n) => o.patch(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e,
  n
), ly = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return py(a, u, n);
  }, ...e };
}, Mf = (t) => {
  const e = ly(t);
  return c(e);
}, my = (t, e) => o.get(
  "/partner_dashboard/capital_tab/csv_export",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), gy = (t) => ["/partner_dashboard/capital_tab/csv_export", ...t ? [t] : []], qy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? gy(t), queryFn: ({ signal: u }) => my(t, { signal: u, ...r }), ...n };
};
function Pf(t, e) {
  const n = qy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const fy = (t, e) => o.post(
  "/partner_dashboard/capital_tab/csv_export",
  t,
  e
), Oy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return fy(a, n);
  }, ...e };
}, xf = (t) => {
  const e = Oy(t);
  return c(e);
}, hy = (t, e) => o.get(
  "/rutter_connections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ky = (t) => ["/rutter_connections", ...t ? [t] : []], by = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ky(t), queryFn: ({ signal: u }) => hy(t, { signal: u, ...r }), ...n };
};
function Bf(t, e) {
  const n = by(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const ky = (t, e) => o.post(
  "/rutter_connections",
  t,
  e
), My = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ky(a, n);
  }, ...e };
}, Rf = (t) => {
  const e = My(t);
  return c(e);
}, Py = (t, e) => o.post(
  "/mark_accounting_integration_seen",
  t,
  e
), xy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Py(a, n);
  }, ...e };
}, Ff = (t) => {
  const e = xy(t);
  return c(e);
}, By = (t) => o.get(
  "/sardine/session_id",
  t
), Ry = () => ["/sardine/session_id"], Fy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ry(), queryFn: ({ signal: a }) => By({ signal: a, ...n }), ...e };
};
function Ef(t) {
  const e = Fy(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Ey = (t, e) => o.post(
  "/sardine/session_id",
  t,
  e
), Cy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ey(a, n);
  }, ...e };
}, Cf = (t) => {
  const e = Cy(t);
  return c(e);
}, vy = (t) => o.get(
  "/partner/widget_configs",
  t
), Uy = () => ["/partner/widget_configs"], Ny = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Uy(), queryFn: ({ signal: a }) => vy({ signal: a, ...n }), ...e };
};
function vf(t) {
  const e = Ny(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Dy = (t, e) => o.patch(
  "/partner/widget_configs",
  t,
  e
), Ay = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Dy(a, n);
  }, ...e };
}, Uf = (t) => {
  const e = Ay(t);
  return c(e);
}, Sy = (t, e) => o.get(
  `/merchant_bank/applications/${t}`,
  e
), Gy = (t) => [`/merchant_bank/applications/${t}`], Iy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Gy(t), queryFn: ({ signal: u }) => Sy(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function Nf(t, e) {
  const n = Iy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ty = (t, e) => o.get(
  "/merchant_bank/applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wy = (t) => ["/merchant_bank/applications", ...t ? [t] : []], Qy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wy(t), queryFn: ({ signal: u }) => Ty(t, { signal: u, ...r }), ...n };
};
function Df(t, e) {
  const n = Qy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ly = (t, e) => o.post(
  "/merchant_bank/applications",
  t,
  e
), Wy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ly(a, n);
  }, ...e };
}, Af = (t) => {
  const e = Wy(t);
  return c(e);
}, zy = (t, e) => o.post(
  "/webhooks/plaid/general",
  t,
  e
), $y = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return zy(a, n);
  }, ...e };
}, Sf = (t) => {
  const e = $y(t);
  return c(e);
}, Vy = (t, e) => o.get(
  "/amazon/funding/account_lock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Hy = (t) => ["/amazon/funding/account_lock", ...t ? [t] : []], Yy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Hy(t), queryFn: ({ signal: u }) => Vy(t, { signal: u, ...r }), ...n };
};
function Gf(t, e) {
  const n = Yy(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const jy = (t) => o.get(
  "/naics",
  t
), Xy = () => ["/naics"], Jy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Xy(), queryFn: ({ signal: a }) => jy({ signal: a, ...n }), ...e };
};
function If(t) {
  const e = Jy(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Zy = (t, e, n, r) => o.post(
  `/mfa/otp/send/${t}/${e}`,
  n,
  r
), td = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: a, phoneNumberType: u, data: y } = s ?? {};
    return Zy(a, u, y, n);
  }, ...e };
}, Tf = (t) => {
  const e = td(t);
  return c(e);
}, ed = (t, e, n, r) => o.post(
  `/mfa/otp/verify/${t}/${e}`,
  n,
  r
), nd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: a, phoneNumberType: u, data: y } = s ?? {};
    return ed(a, u, y, n);
  }, ...e };
}, wf = (t) => {
  const e = nd(t);
  return c(e);
}, rd = (t, e) => o.get(
  "/funding/pad_agreement",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), sd = (t) => ["/funding/pad_agreement", ...t ? [t] : []], ad = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? sd(t), queryFn: ({ signal: u }) => rd(t, { signal: u, ...r }), ...n };
};
function Qf(t, e) {
  const n = ad(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const od = (t, e) => o.post(
  "/funding/mark_pad_agreement_signed",
  t,
  e
), ud = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return od(a, n);
  }, ...e };
}, Lf = (t) => {
  const e = ud(t);
  return c(e);
}, id = (t, e) => o.get(
  "/templates",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), cd = (t) => ["/templates", ...t ? [t] : []], yd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? cd(t), queryFn: ({ signal: u }) => id(t, { signal: u, ...r }), ...n };
};
function Wf(t, e) {
  const n = yd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const dd = (t, e) => o.post(
  "/templates",
  t,
  e
), _d = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return dd(a, n);
  }, ...e };
}, zf = (t) => {
  const e = _d(t);
  return c(e);
}, pd = (t, e) => o.patch(
  "/templates",
  t,
  e
), ld = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return pd(a, n);
  }, ...e };
}, $f = (t) => {
  const e = ld(t);
  return c(e);
}, md = (t, e) => o.get(
  "/template_mock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), gd = (t) => ["/template_mock", ...t ? [t] : []], qd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? gd(t), queryFn: ({ signal: u }) => md(t, { signal: u, ...r }), ...n };
};
function Vf(t, e) {
  const n = qd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const fd = (t, e) => o.get(
  "/template_snapshots",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Od = (t) => ["/template_snapshots", ...t ? [t] : []], hd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Od(t), queryFn: ({ signal: u }) => fd(t, { signal: u, ...r }), ...n };
};
function Hf(t, e) {
  const n = hd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Kd = (t, e) => o.get(
  "/template_snapshot_urls",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), bd = (t) => ["/template_snapshot_urls", ...t ? [t] : []], kd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? bd(t), queryFn: ({ signal: u }) => Kd(t, { signal: u, ...r }), ...n };
};
function Yf(t, e) {
  const n = kd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Md = (t) => o.get(
  "/template_types",
  t
), Pd = () => ["/template_types"], xd = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Pd(), queryFn: ({ signal: a }) => Md({ signal: a, ...n }), ...e };
};
function jf(t) {
  const e = xd(t), n = i(e);
  return n.queryKey = e.queryKey, n;
}
const Bd = (t, e) => o.post(
  "/template_types",
  t,
  e
), Rd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Bd(a, n);
  }, ...e };
}, Xf = (t) => {
  const e = Rd(t);
  return c(e);
}, Fd = (t, e) => o.post(
  "/manual_repayment",
  t,
  e
), Ed = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Fd(a, n);
  }, ...e };
}, Jf = (t) => {
  const e = Ed(t);
  return c(e);
}, Cd = (t, e) => o.post(
  "/forgive_capital_fee",
  t,
  e
), vd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Cd(a, n);
  }, ...e };
}, Zf = (t) => {
  const e = vd(t);
  return c(e);
}, Ud = (t, e) => o.post(
  "/mfa/verify_account_details",
  t,
  e
), Nd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ud(a, n);
  }, ...e };
}, tO = (t) => {
  const e = Nd(t);
  return c(e);
}, Dd = (t) => o.patch(
  "/mfa/extend_session",
  void 0,
  t
), Ad = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Dd(n), ...e };
}, eO = (t) => {
  const e = Ad(t);
  return c(e);
}, Sd = (t, e) => o.get(
  "/merchant_bank/offers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Gd = (t) => ["/merchant_bank/offers", ...t ? [t] : []], Id = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Gd(t), queryFn: ({ signal: u }) => Sd(t, { signal: u, ...r }), ...n };
};
function nO(t, e) {
  const n = Id(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Td = (t, e) => o.get(
  "/internal_api/sardine/score_details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wd = (t) => ["/internal_api/sardine/score_details", ...t ? [t] : []], Qd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wd(t), queryFn: ({ signal: u }) => Td(t, { signal: u, ...r }), ...n };
};
function rO(t, e) {
  const n = Qd(t, e), r = i(n);
  return r.queryKey = n.queryKey, r;
}
const Ld = (t, e) => o.patch(
  "/merchant_bank/merchant_config",
  t,
  e
), Wd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ld(a, n);
  }, ...e };
}, sO = (t) => {
  const e = Wd(t);
  return c(e);
}, aO = (t, e) => i({
  queryKey: ["plaid-link-token", t],
  queryFn: () => o.get("/funding/plaid_link_token", { ...e == null ? void 0 : e.axios, params: t })
}), oO = (t, e) => i({
  queryKey: ["card-offers", t.business_id],
  queryFn: () => o.get("/card/offers", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), uO = (t, e) => {
  var n;
  return i({
    queryKey: ["card-application", t.business_id],
    queryFn: () => o.get("/card/applications", {
      ...e == null ? void 0 : e.axios,
      params: t
    }),
    enabled: (n = e == null ? void 0 : e.query) == null ? void 0 : n.enabled
  });
}, iO = (t, e) => i({
  queryKey: ["cardholder", t.business_id],
  queryFn: () => o.get("/cardholders", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), cO = (t, e) => o.get("/statements", {
  ...e == null ? void 0 : e.axios,
  params: t
}), yO = (t, e) => i({
  queryKey: ["card-transactions"],
  queryFn: async () => {
    const n = await o.get("/card/transactions", {
      ...e == null ? void 0 : e.axios,
      params: t
    });
    return {
      ...n,
      data: {
        ...n.data,
        results: n.data.results.map((r) => ({
          ...r,
          amount: r.amount * -1,
          state: r.amount <= 0 || r.transaction_type === "chargeback" ? "succeeded" : "refunded",
          activity_type: r.transaction_type === "chargeback" ? "dispute_credit" : "transaction",
          ...r.transaction_type === "chargeback" && {
            related_card_transaction: n.data.results.find(
              ({ id: s }) => s === r.related_card_transaction_id
            )
          }
        }))
      }
    };
  }
}), dO = (t, e) => i({
  queryKey: ["pending-card-transactions"],
  queryFn: async () => {
    const n = await o.get("/card/pending_transactions", {
      ...e == null ? void 0 : e.axios,
      params: t
    });
    return {
      ...n,
      data: {
        ...n.data,
        results: n.data.results.map((r) => ({
          ...r,
          amount: r.amount * -1,
          state: "pending",
          activity_type: "transaction"
        }))
      }
    };
  }
}), _O = (t, e) => i({
  queryKey: ["card-repayments"],
  queryFn: async () => {
    const n = await o.get("/repayments", {
      ...e == null ? void 0 : e.axios,
      params: t
    });
    return {
      ...n,
      data: {
        ...n.data,
        results: n.data.results.filter((r) => r.state === "complete" || r.state === "pending").map((r) => ({
          ...r,
          timestamp: r.date,
          activity_type: "repayment",
          state: r.is_settled ? r.state : "pending"
        }))
      }
    };
  }
}), pO = (t, e) => i({
  queryKey: ["card-balance", t.business_id],
  queryFn: () => o.get("/card/balances", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), lO = (t, e) => i({
  queryKey: [`card-access-token-${t.card_id}`],
  queryFn: () => o.get("/card/access_token", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), mO = (t, e) => i({
  queryKey: ["microdeposit-link-token", t.business_id],
  queryFn: () => o.get("/funding/micro_deposit_verification_plaid_link_token", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), gO = (t, e) => i({
  queryKey: ["show-top-up-offer", t.business_id],
  queryFn: () => o.get("/businesses/show_top_up_offer", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), qO = (t, e) => i({
  queryKey: ["whitelisted-for-early-payment", t.cash_advance_id],
  queryFn: () => o.get("/early_manual_repayment_whitelist", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), fO = (t, e) => i({
  queryKey: ["opt-out-eligibility", t.business_external_id],
  queryFn: () => o.get("/opt_out_eligibility", { ...e == null ? void 0 : e.axios, params: t })
}), OO = (t, e) => {
  var r;
  const n = (r = e == null ? void 0 : e.query) == null ? void 0 : r.enabled;
  return i({
    queryKey: ["card-lifecycle-state", t.business_id],
    queryFn: () => o.get("/card/lifecycle_states", {
      ...e == null ? void 0 : e.axios,
      params: t
    }),
    enabled: n
  });
}, hO = (t, e) => i({
  queryKey: ["card-offer", t.business_id],
  queryFn: () => o.get("/card/offers", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), KO = (t, e) => i({
  queryKey: ["opt-ins", t.business_id],
  queryFn: () => o.get("/opt_ins", {
    ...e == null ? void 0 : e.axios,
    params: t
  })
}), bO = (t) => c({
  mutationFn: (e) => o.post("/beneficial_owners", e, t == null ? void 0 : t.axios)
}), kO = (t) => c({
  mutationFn: ({
    id: e,
    ...n
  }) => o.patch(`/beneficial_owners/${e}`, n, t == null ? void 0 : t.axios)
}), MO = (t) => c({
  mutationFn: (e) => o.delete(`/beneficial_owners/${e}`, t == null ? void 0 : t.axios)
}), PO = (t) => c({
  mutationFn: (e) => o.post(`/business/${e.business_id}/kyc`, e, t == null ? void 0 : t.axios)
}), xO = (t) => c({
  mutationFn: (e) => o.post("/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), BO = (t) => c({
  mutationFn: (e) => o.post("/amazon/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), RO = (t) => c({
  mutationFn: (e) => o.post("/funding/bank_statement_upload", e, t == null ? void 0 : t.axios)
}), FO = (t) => c({
  mutationFn: (e) => o.post("/underwriting_attempts", { context: e }, t == null ? void 0 : t.axios)
}), EO = (t) => c({
  mutationFn: (e) => o.post(
    "/amazonlending/create_or_update_amazon_resources_pre_login",
    e,
    t == null ? void 0 : t.axios
  )
}), CO = (t) => c({
  mutationFn: (e) => o.patch("/boost_complete", e, t == null ? void 0 : t.axios)
}), vO = (t) => c({
  mutationFn: (e) => o.patch(`/cardholders/${e.id}`, e, t == null ? void 0 : t.axios)
}), UO = (t) => c({
  mutationFn: (e) => o.post("/card/disputes", e, t == null ? void 0 : t.axios)
}), NO = (t) => c({
  mutationFn: (e) => o.post("/card/activate", e, t == null ? void 0 : t.axios)
}), DO = (t) => c({
  mutationFn: async (e) => (await o.post("/card/apple_pay", e, t == null ? void 0 : t.axios)).data
}), AO = (t) => c({
  mutationFn: (e) => o.post("/card/google_pay", e, t == null ? void 0 : t.axios)
}), SO = (t) => c({
  mutationFn: (e) => o.post(
    "/funding/accounts/initiate_micro_deposit_verification",
    e,
    t == null ? void 0 : t.axios
  )
}), GO = (t) => c({
  mutationFn: (e) => o.post(
    "/funding/accounts/fail_micro_deposit_verification",
    e,
    t == null ? void 0 : t.axios
  )
}), IO = (t) => c({
  mutationFn: (e) => o.post(
    "/funding/create_unverified_bank_accounts_from_plaid_linkage",
    e,
    t == null ? void 0 : t.axios
  )
}), TO = (t) => c({
  mutationFn: (e) => o.post("/amazon/funding/verify_bank_account", e, t == null ? void 0 : t.axios)
}), wO = (t) => c({
  mutationFn: (e) => o.post("/funding/plaid_linkages", e, t == null ? void 0 : t.axios)
}), QO = (t) => c({
  mutationFn: (e) => o.post("/tickets", e, t == null ? void 0 : t.axios)
}), LO = (t) => c({
  mutationFn: (e) => o.post("/opt_out", e, t == null ? void 0 : t.axios)
}), WO = (t) => c({
  mutationFn: (e) => o.post("/opt_ins", e, t == null ? void 0 : t.axios)
}), zO = [
  {
    path: "/v1/auth/redeem_token",
    method: "post",
    name: "Redeem Auth Token"
  },
  {
    path: "/v1/businesses",
    method: "get",
    name: "List Businesses"
  },
  {
    path: "/v1/businesses",
    method: "post",
    name: "Create Business"
  },
  {
    path: "/v1/businesses/batch_create",
    method: "post",
    name: "Batch Create Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "get",
    name: "Get Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "patch",
    name: "Update Business"
  },
  {
    path: "/v1/daily_sales_records",
    method: "get",
    name: "List Daily Sales Records"
  },
  {
    path: "/v1/daily_sales_records",
    method: "post",
    name: "Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/batch_create",
    method: "post",
    name: "Batch Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "get",
    name: "Get Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "patch",
    name: "Update Daily Sale Record"
  },
  {
    path: "/v1/bank_accounts",
    method: "get",
    name: "List Bank Accounts"
  },
  {
    path: "/v1/bank_accounts",
    method: "post",
    name: "Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/batch_create",
    method: "post",
    name: "Batch Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/{id}",
    method: "get",
    name: "Get Bank Account"
  },
  {
    path: "/v1/persons",
    method: "get",
    name: "List Persons"
  },
  {
    path: "/v1/persons",
    method: "post",
    name: "Create Person"
  },
  {
    path: "/v1/persons/batch_create",
    method: "post",
    name: "Batch Create Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "get",
    name: "Get Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "patch",
    name: "Update Person"
  },
  {
    path: "/v1/sandbox/trigger_webhook",
    method: "post",
    name: "Trigger Webhook"
  }
];
export {
  sl as AccountOwner,
  rl as AddressType,
  nl as BankAccountInfoDataShare,
  el as BusinessProductType,
  tl as CapitalProductActivityType,
  Zp as CapitalProductApplicationStatus,
  Jp as CapitalProductApplicationType,
  Xp as CapitalProductHealthStatus,
  jp as CapitalProductInternalRepaymentPlanType,
  Yp as CapitalProductOfferLabel,
  Hp as CapitalProductState,
  Vp as CapitalProductType,
  $p as CardBusinessTableRowStatus,
  zp as CardDeserializerCardType,
  wp as CardSerializer1FulfillmentStatus,
  Tp as CardSerializer1State,
  Wp as CardSerializerCardType,
  Lp as CardSerializerFulfillmentStatus,
  Qp as CardSerializerState,
  Ip as CardShippingMethod,
  Gp as CardTransitionChannel,
  Sp as CardTransitionReasonCode,
  Ap as CardTransitionState,
  Dp as CardTransitionType,
  Np as CashAdvanceChargeOffSerializerChargeOffType,
  Up as ChargeOffInputSerializerChargeOffType,
  vp as CountryCode,
  Cp as CrmDeliveryAttemptState,
  Ep as CrmEventType,
  Fp as CrmVendor,
  Rp as Currency,
  Bp as DataRequirement,
  xp as DatasetType,
  Pp as DatasetUploadStatus,
  Mp as DatashareMethod,
  kp as DeniedReason,
  bp as Direction,
  Kp as DocumentUploadPurpose,
  hp as DoordashAdminUserMxRole,
  Op as EntityType,
  fp as FieldType,
  qp as FinancialRecordDocType,
  gp as FutureActivityType,
  mp as IdentityDataShare,
  lp as IncorporationJurisdiction,
  pp as IncorporationType,
  _p as InfoNeededStatus,
  dp as IngestionStatus,
  yp as InnerTransferType,
  cp as KycProvider,
  ip as KycReviewReason,
  up as LienStatus,
  op as LienType,
  ap as LoanBusinessTableRowStatus,
  sp as ManualReviewReason,
  rp as McaBusinessTableRowStatus,
  np as MerchantBankAccountType,
  ep as MerchantBankApplicationState,
  tp as MerchantBankCardDisputeReason,
  Z_ as MerchantBankCardDisputeState,
  J_ as MerchantBankCardPaymentPreference,
  X_ as MerchantBankCardReplacementReason,
  j_ as MerchantBankCardType,
  Y_ as MerchantBankExternalAccountState,
  H_ as MerchantBankExternalBankAccountType,
  V_ as MerchantBankReceivedTransferType,
  $_ as MerchantBankRecipientPaymentType,
  z_ as MerchantBankRepaymentType,
  W_ as MerchantBankTransactionDestinationAccountType,
  L_ as MerchantBankTransactionPaymentDirection,
  Q_ as MerchantBankTransactionState,
  w_ as MerchantBankTransactionType,
  T_ as MerchantBankTransferPaymentType,
  I_ as MicroDepositVerificationState,
  G_ as MinimumRepaymentAmountDueReason,
  S_ as NotificationType,
  A_ as OnboardingAction,
  D_ as OnboardingProductType,
  N_ as OnboardingState,
  U_ as OneOffActionType,
  v_ as OptInState,
  C_ as ParafinProduct,
  E_ as PersonaInquiryType,
  F_ as PhoneNumberCountryCode,
  R_ as PhoneNumberType,
  B_ as PlaidInvestigationDeniedReason,
  x_ as PlaidLinkageWithInvestigationSerializerState,
  P_ as RepaymentDetailsRepaymentPlanType,
  M_ as RepaymentDisbursementType,
  k_ as RepaymentPauseSerializerPauseType,
  b_ as RepaymentSchedule,
  K_ as ReplacementDetailsReplacementReason,
  h_ as RestrictionExternalReason,
  O_ as RestrictionReason,
  f_ as RestrictionSource,
  q_ as RestrictionType,
  g_ as ReturnCode,
  m_ as ReversalReason,
  l_ as RutterConnectionState,
  p_ as RutterIntegrationType,
  __ as RutterPlatform,
  d_ as S3PartnerDashboardCsvStatus,
  y_ as S3PartnerDashboardCsvTableType,
  c_ as SalesDataShare,
  i_ as SardineCustomerScoreState,
  u_ as SardineFlowName,
  o_ as SardineLevel,
  a_ as SessionType,
  s_ as State,
  r_ as StripeSaleRecordIngestionType,
  n_ as TermsOfServiceLinkType,
  e_ as UnderwritingDesiredOutputState,
  t_ as UserRole,
  Zd as ValidationStatus,
  Jd as VerificationStatus,
  Xd as WebhookDeliveryAttemptState,
  jd as WebhookEndpointStatus,
  Yd as WebhookEventDeliveryState,
  Hd as WebhookEventMode,
  Vd as WebhookEventType,
  Zc as attemptSync,
  Tc as batchResendWebhookAttempt,
  qr as benchmarkGetCapitalProductOwnership,
  Wi as countPdBusinessTableRows,
  nc as countPdCapitalProductApplicationRow,
  ic as countPdCapitalProductOfferRow,
  ji as countPdCapitalProductRow,
  Hr as createBankAccountInfo,
  Rr as createCapitalProductIntents,
  Yn as createCapitalProducts,
  kr as createOfferedCapitalProductFeeDiscount,
  it as createOrganization,
  rt as createPartnerApiKey,
  cy as createPdWebhookEndpoint,
  os as createPlaidInvestigation,
  di as createS3OfferCsv,
  fy as createS3PartnerDashboardTableCsv,
  Oe as deleteBeneficialOwnersId,
  He as deleteBusinessOfficersId,
  N as deleteCashAdvancePausesP1,
  oe as deleteMarketingIntegrationsVendor,
  Mu as deleteMerchantBankExternalAccountsId,
  Ko as deleteMerchantBankRecipientsId,
  Ft as deletePartnerDeleteUsers,
  Wt as deletePartnerSyncWebhooksId,
  dy as deletePdWebhookEndpoint,
  ht as deleteSlackChannelsId,
  Vy as getAmazonFundingAccountLock,
  ni as getAnalyticsCubeToken,
  ty as getAttemptSyncMutationOptions,
  zr as getBankAccountInfos,
  Re as getBanks,
  wc as getBatchResendWebhookAttemptMutationOptions,
  fr as getBenchmarkGetCapitalProductOwnershipQueryKey,
  Or as getBenchmarkGetCapitalProductOwnershipQueryOptions,
  le as getBeneficialOwners,
  g as getBusinessAnnotations,
  d as getBusinessCores,
  gs as getBusinessIdentities,
  Le as getBusinessOfficers,
  Te as getBusinessTableRowsCard,
  Se as getBusinessTableRowsLoan,
  Ne as getBusinessTableRowsMca,
  Ce as getBusinessTableRowsProductAgnostic,
  ka as getBusinessesDailySalesRecords,
  pi as getBusinessesNotifications,
  Ys as getBusinessesProducts,
  sr as getCapitalProductActivities,
  Pr as getCapitalProductIntents,
  dr as getCapitalProductNetTopUpItemization,
  Zn as getCapitalProductOfferCollection,
  Ln as getCapitalProductProspectiveTermsOfService,
  hr as getCapitalProductRepaymentSchedule,
  lr as getCapitalProductStateDisclosure,
  $n as getCapitalProducts,
  Ws as getCards,
  K as getCashAdvanceChargeOffs,
  F as getCashAdvancePauses,
  zi as getCountPdBusinessTableRowsQueryKey,
  $i as getCountPdBusinessTableRowsQueryOptions,
  rc as getCountPdCapitalProductApplicationRowQueryKey,
  sc as getCountPdCapitalProductApplicationRowQueryOptions,
  cc as getCountPdCapitalProductOfferRowQueryKey,
  yc as getCountPdCapitalProductOfferRowQueryOptions,
  Xi as getCountPdCapitalProductRowQueryKey,
  Ji as getCountPdCapitalProductRowQueryOptions,
  Yr as getCreateBankAccountInfoMutationOptions,
  Fr as getCreateCapitalProductIntentsMutationOptions,
  jn as getCreateCapitalProductsMutationOptions,
  Mr as getCreateOfferedCapitalProductFeeDiscountMutationOptions,
  ct as getCreateOrganizationMutationOptions,
  st as getCreatePartnerApiKeyMutationOptions,
  yy as getCreatePdWebhookEndpointMutationOptions,
  us as getCreatePlaidInvestigationMutationOptions,
  _i as getCreateS3OfferCsvMutationOptions,
  Oy as getCreateS3PartnerDashboardTableCsvMutationOptions,
  kn as getDataingestionS3DatasetIngestionConfigs,
  pn as getDataingestionS3DatasetIntegrations,
  yn as getDataingestionS3DatasetUploadValidationResults,
  on as getDataingestionS3DatasetUploads,
  fn as getDataingestionS3OrgDatasetIntegrations,
  Un as getDataingestionStripeIngestions,
  Iu as getDebtChecks,
  vu as getDebts,
  he as getDeleteBeneficialOwnersIdMutationOptions,
  Ye as getDeleteBusinessOfficersIdMutationOptions,
  D as getDeleteCashAdvancePausesP1MutationOptions,
  ue as getDeleteMarketingIntegrationsVendorMutationOptions,
  Pu as getDeleteMerchantBankExternalAccountsIdMutationOptions,
  bo as getDeleteMerchantBankRecipientsIdMutationOptions,
  Et as getDeletePartnerDeleteUsersMutationOptions,
  zt as getDeletePartnerSyncWebhooksIdMutationOptions,
  _y as getDeletePdWebhookEndpointMutationOptions,
  Kt as getDeleteSlackChannelsIdMutationOptions,
  ta as getDocumentUploadGroup,
  aa as getDocumentUploadList,
  Wu as getFinancialReviews,
  vr as getFlexLoanMinimumRepaymentSchedule,
  Jr as getFundingAccounts,
  w as getFutureActivities,
  Hy as getGetAmazonFundingAccountLockQueryKey,
  Yy as getGetAmazonFundingAccountLockQueryOptions,
  ri as getGetAnalyticsCubeTokenQueryKey,
  si as getGetAnalyticsCubeTokenQueryOptions,
  $r as getGetBankAccountInfosQueryKey,
  Vr as getGetBankAccountInfosQueryOptions,
  Fe as getGetBanksQueryKey,
  Ee as getGetBanksQueryOptions,
  me as getGetBeneficialOwnersQueryKey,
  ge as getGetBeneficialOwnersQueryOptions,
  q as getGetBusinessAnnotationsQueryKey,
  f as getGetBusinessAnnotationsQueryOptions,
  _ as getGetBusinessCoresQueryKey,
  p as getGetBusinessCoresQueryOptions,
  qs as getGetBusinessIdentitiesQueryKey,
  fs as getGetBusinessIdentitiesQueryOptions,
  We as getGetBusinessOfficersQueryKey,
  ze as getGetBusinessOfficersQueryOptions,
  we as getGetBusinessTableRowsCardQueryKey,
  Qe as getGetBusinessTableRowsCardQueryOptions,
  Ge as getGetBusinessTableRowsLoanQueryKey,
  Ie as getGetBusinessTableRowsLoanQueryOptions,
  De as getGetBusinessTableRowsMcaQueryKey,
  Ae as getGetBusinessTableRowsMcaQueryOptions,
  ve as getGetBusinessTableRowsProductAgnosticQueryKey,
  Ue as getGetBusinessTableRowsProductAgnosticQueryOptions,
  Ma as getGetBusinessesDailySalesRecordsQueryKey,
  Pa as getGetBusinessesDailySalesRecordsQueryOptions,
  li as getGetBusinessesNotificationsQueryKey,
  mi as getGetBusinessesNotificationsQueryOptions,
  js as getGetBusinessesProductsQueryKey,
  Xs as getGetBusinessesProductsQueryOptions,
  ar as getGetCapitalProductActivitiesQueryKey,
  or as getGetCapitalProductActivitiesQueryOptions,
  xr as getGetCapitalProductIntentsQueryKey,
  Br as getGetCapitalProductIntentsQueryOptions,
  _r as getGetCapitalProductNetTopUpItemizationQueryKey,
  pr as getGetCapitalProductNetTopUpItemizationQueryOptions,
  tr as getGetCapitalProductOfferCollectionQueryKey,
  er as getGetCapitalProductOfferCollectionQueryOptions,
  Wn as getGetCapitalProductProspectiveTermsOfServiceQueryKey,
  zn as getGetCapitalProductProspectiveTermsOfServiceQueryOptions,
  Kr as getGetCapitalProductRepaymentScheduleQueryKey,
  br as getGetCapitalProductRepaymentScheduleQueryOptions,
  mr as getGetCapitalProductStateDisclosureQueryKey,
  gr as getGetCapitalProductStateDisclosureQueryOptions,
  Vn as getGetCapitalProductsQueryKey,
  Hn as getGetCapitalProductsQueryOptions,
  zs as getGetCardsQueryKey,
  $s as getGetCardsQueryOptions,
  b as getGetCashAdvanceChargeOffsQueryKey,
  k as getGetCashAdvanceChargeOffsQueryOptions,
  E as getGetCashAdvancePausesQueryKey,
  C as getGetCashAdvancePausesQueryOptions,
  Mn as getGetDataingestionS3DatasetIngestionConfigsQueryKey,
  Pn as getGetDataingestionS3DatasetIngestionConfigsQueryOptions,
  ln as getGetDataingestionS3DatasetIntegrationsQueryKey,
  mn as getGetDataingestionS3DatasetIntegrationsQueryOptions,
  dn as getGetDataingestionS3DatasetUploadValidationResultsQueryKey,
  _n as getGetDataingestionS3DatasetUploadValidationResultsQueryOptions,
  un as getGetDataingestionS3DatasetUploadsQueryKey,
  cn as getGetDataingestionS3DatasetUploadsQueryOptions,
  On as getGetDataingestionS3OrgDatasetIntegrationsQueryKey,
  hn as getGetDataingestionS3OrgDatasetIntegrationsQueryOptions,
  Nn as getGetDataingestionStripeIngestionsQueryKey,
  Dn as getGetDataingestionStripeIngestionsQueryOptions,
  Tu as getGetDebtChecksQueryKey,
  wu as getGetDebtChecksQueryOptions,
  Uu as getGetDebtsQueryKey,
  Nu as getGetDebtsQueryOptions,
  ea as getGetDocumentUploadGroupQueryKey,
  na as getGetDocumentUploadGroupQueryOptions,
  oa as getGetDocumentUploadListQueryKey,
  ua as getGetDocumentUploadListQueryOptions,
  zu as getGetFinancialReviewsQueryKey,
  $u as getGetFinancialReviewsQueryOptions,
  Ur as getGetFlexLoanMinimumRepaymentScheduleQueryKey,
  Nr as getGetFlexLoanMinimumRepaymentScheduleQueryOptions,
  Zr as getGetFundingAccountsQueryKey,
  ts as getGetFundingAccountsQueryOptions,
  Q as getGetFutureActivitiesQueryKey,
  L as getGetFutureActivitiesQueryOptions,
  qa as getGetLiensQueryKey,
  fa as getGetLiensQueryOptions,
  ce as getGetMarketingGtmToolkitQueryKey,
  ye as getGetMarketingGtmToolkitQueryOptions,
  ne as getGetMarketingIntegrationsQueryKey,
  re as getGetMarketingIntegrationsQueryOptions,
  _e as getGetMarketingParagonTokenQueryKey,
  pe as getGetMarketingParagonTokenQueryOptions,
  Qa as getGetMerchantBankAccountsIdBalancesQueryKey,
  La as getGetMerchantBankAccountsIdBalancesQueryOptions,
  Ia as getGetMerchantBankAccountsIdQueryKey,
  Ta as getGetMerchantBankAccountsIdQueryOptions,
  za as getGetMerchantBankAccountsQueryKey,
  $a as getGetMerchantBankAccountsQueryOptions,
  Gy as getGetMerchantBankApplicationsIdQueryKey,
  Iy as getGetMerchantBankApplicationsIdQueryOptions,
  wy as getGetMerchantBankApplicationsQueryKey,
  Qy as getGetMerchantBankApplicationsQueryOptions,
  Wo as getGetMerchantBankCardAccessTokenQueryKey,
  zo as getGetMerchantBankCardAccessTokenQueryOptions,
  Yo as getGetMerchantBankCardCardholderAccessTokenQueryKey,
  jo as getGetMerchantBankCardCardholderAccessTokenQueryOptions,
  Io as getGetMerchantBankCardCardholdersQueryKey,
  To as getGetMerchantBankCardCardholdersQueryOptions,
  nu as getGetMerchantBankCardDisputesQueryKey,
  ru as getGetMerchantBankCardDisputesQueryOptions,
  vo as getGetMerchantBankCardsQueryKey,
  Uo as getGetMerchantBankCardsQueryOptions,
  lu as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryKey,
  mu as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryOptions,
  bu as getGetMerchantBankExternalAccountsIdQueryKey,
  ku as getGetMerchantBankExternalAccountsIdQueryOptions,
  du as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryKey,
  _u as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryOptions,
  qu as getGetMerchantBankExternalAccountsQueryKey,
  fu as getGetMerchantBankExternalAccountsQueryOptions,
  yo as getGetMerchantBankInterestDetailsQueryKey,
  _o as getGetMerchantBankInterestDetailsQueryOptions,
  Gd as getGetMerchantBankOffersQueryKey,
  Id as getGetMerchantBankOffersQueryOptions,
  Oo as getGetMerchantBankRecipientsIdQueryKey,
  ho as getGetMerchantBankRecipientsIdQueryOptions,
  lo as getGetMerchantBankRecipientsQueryKey,
  mo as getGetMerchantBankRecipientsQueryOptions,
  Ja as getGetMerchantBankTransactionsIdQueryKey,
  Za as getGetMerchantBankTransactionsIdQueryOptions,
  eo as getGetMerchantBankTransactionsQueryKey,
  no as getGetMerchantBankTransactionsQueryOptions,
  so as getGetMerchantBankTransferFeesQueryKey,
  ao as getGetMerchantBankTransferFeesQueryOptions,
  uo as getGetMerchantBankTransferLimitsQueryKey,
  io as getGetMerchantBankTransferLimitsQueryOptions,
  Xy as getGetNaicsQueryKey,
  Jy as getGetNaicsQueryOptions,
  Ju as getGetOfficerSubsidiaryRecordsQueryKey,
  Zu as getGetOfficerSubsidiaryRecordsQueryOptions,
  wn as getGetOnboardingBusinessRequirementsQueryKey,
  Qn as getGetOnboardingBusinessRequirementsQueryOptions,
  ls as getGetOnboardingInfoNeededQueryKey,
  ms as getGetOnboardingInfoNeededQueryOptions,
  ds as getGetOnboardingStatesQueryKey,
  _s as getGetOnboardingStatesQueryOptions,
  pt as getGetOrganizationQueryKey,
  lt as getGetOrganizationQueryOptions,
  Me as getGetOwnerIsBeneficialOwnerQueryKey,
  Pe as getGetOwnerIsBeneficialOwnerQueryOptions,
  bs as getGetOwnersQueryKey,
  ks as getGetOwnersQueryOptions,
  sd as getGetPADAgreementQueryKey,
  ad as getGetPADAgreementQueryOptions,
  Ea as getGetParafinderBusinessTypesQueryKey,
  Ca as getGetParafinderBusinessTypesQueryOptions,
  Aa as getGetParafinderCapitalInfoQueryKey,
  Sa as getGetParafinderCapitalInfoQueryOptions,
  Ba as getGetParafinderQueryKey,
  Ra as getGetParafinderQueryOptions,
  Ua as getGetParafinderSummaryQueryKey,
  Na as getGetParafinderSummaryQueryOptions,
  et as getGetPartnerApiKeyQueryKey,
  nt as getGetPartnerApiKeyQueryOptions,
  Dt as getGetPartnerConfigsQueryKey,
  At as getGetPartnerConfigsQueryOptions,
  vt as getGetPartnerPublicConfigsQueryKey,
  Ut as getGetPartnerPublicConfigsQueryOptions,
  Tt as getGetPartnerSyncWebhooksQueryKey,
  wt as getGetPartnerSyncWebhooksQueryOptions,
  kt as getGetPartnerUsersQueryKey,
  Mt as getGetPartnerUsersQueryOptions,
  Uy as getGetPartnerWidgetConfigsQueryKey,
  Ny as getGetPartnerWidgetConfigsQueryOptions,
  B as getGetPartnersDoordashAdminUsersQueryKey,
  R as getGetPartnersDoordashAdminUsersQueryOptions,
  z as getGetPartnersQueryKey,
  $ as getGetPartnersQueryOptions,
  j as getGetPartnersUnderwritingQueryKey,
  X as getGetPartnersUnderwritingQueryOptions,
  fc as getGetPdOfferUrlQueryKey,
  Oc as getGetPdOfferUrlQueryOptions,
  Mc as getGetPdUtilityInfoQueryKey,
  Pc as getGetPdUtilityInfoQueryOptions,
  ss as getGetPlaidInvestigationsQueryKey,
  as as getGetPlaidInvestigationsQueryOptions,
  I as getGetRepaymentPlanParametersQueryKey,
  T as getGetRepaymentPlanParametersQueryOptions,
  ca as getGetRestrictionQueryKey,
  ya as getGetRestrictionQueryOptions,
  la as getGetRestrictionsDetailsQueryKey,
  ma as getGetRestrictionsDetailsQueryOptions,
  Gr as getGetRunOfacScreeningResultsQueryKey,
  Ir as getGetRunOfacScreeningResultsQueryOptions,
  Ky as getGetRutterConnectionsQueryKey,
  by as getGetRutterConnectionsQueryOptions,
  Ry as getGetSardineSessionIdQueryKey,
  Fy as getGetSardineSessionIdQueryOptions,
  ft as getGetSlackChannelsQueryKey,
  Ot as getGetSlackChannelsQueryOptions,
  Xt as getGetStatementsV2QueryKey,
  Jt as getGetStatementsV2QueryOptions,
  ay as getGetSyncAndMaybeMarkFailedMutationOptions,
  ny as getGetSyncQueryKey,
  ry as getGetSyncQueryOptions,
  gd as getGetTemplateMockQueryKey,
  qd as getGetTemplateMockQueryOptions,
  bd as getGetTemplateSnapshotQueryKey,
  kd as getGetTemplateSnapshotQueryOptions,
  Od as getGetTemplateSnapshotsQueryKey,
  hd as getGetTemplateSnapshotsQueryOptions,
  Pd as getGetTemplateTypesQueryKey,
  xd as getGetTemplateTypesQueryOptions,
  cd as getGetTemplatesQueryKey,
  yd as getGetTemplatesQueryOptions,
  Lr as getGetTermLoanProspectiveRepaymentsQueryKey,
  Wr as getGetTermLoanProspectiveRepaymentsQueryOptions,
  ga as getLiens,
  dt as getLinkPartnerMutationOptions,
  oi as getListApiRequestsQueryKey,
  ui as getListApiRequestsQueryOptions,
  en as getListDatasetUploadsQueryKey,
  nn as getListDatasetUploadsQueryOptions,
  sn as getListManualDatasetUploadValidationResultsQueryKey,
  an as getListManualDatasetUploadValidationResultsQueryOptions,
  ot as getListOrganizationsQueryKey,
  ut as getListOrganizationsQueryOptions,
  Ki as getListPdBusinessDetailsApplicationsQueryKey,
  bi as getListPdBusinessDetailsApplicationsQueryOptions,
  Mi as getListPdBusinessDetailsBankInfoQueryKey,
  Pi as getListPdBusinessDetailsBankInfoQueryOptions,
  Bi as getListPdBusinessDetailsBusinessInfoQueryKey,
  Ri as getListPdBusinessDetailsBusinessInfoQueryOptions,
  Ui as getListPdBusinessDetailsFundedProductDetailsQueryKey,
  Ni as getListPdBusinessDetailsFundedProductDetailsQueryOptions,
  Ai as getListPdBusinessDetailsFundedProductPaymentProgressQueryKey,
  Si as getListPdBusinessDetailsFundedProductPaymentProgressQueryOptions,
  Ei as getListPdBusinessDetailsFundedProductsQueryKey,
  Ci as getListPdBusinessDetailsFundedProductsQueryOptions,
  Ii as getListPdBusinessDetailsOffersQueryKey,
  Ti as getListPdBusinessDetailsOffersQueryOptions,
  Qi as getListPdBusinessDetailsOwnerInfoQueryKey,
  Li as getListPdBusinessDetailsOwnerInfoQueryOptions,
  Hi as getListPdBusinessTableRowsQueryKey,
  Yi as getListPdBusinessTableRowsQueryOptions,
  oc as getListPdCapitalProductApplicationRowsQueryKey,
  uc as getListPdCapitalProductApplicationRowsQueryOptions,
  _c as getListPdCapitalProductOfferRowsQueryKey,
  pc as getListPdCapitalProductOfferRowsQueryOptions,
  tc as getListPdCapitalProductRowsQueryKey,
  ec as getListPdCapitalProductRowsQueryOptions,
  $c as getListPdCrmDeliveryAttemptsQueryKey,
  Vc as getListPdCrmDeliveryAttemptsQueryOptions,
  Xc as getListPdCrmEventInfoQueryKey,
  Jc as getListPdCrmEventInfoQueryOptions,
  Lc as getListPdCrmEventsQueryKey,
  Wc as getListPdCrmEventsQueryOptions,
  Kc as getListPdCrmIntegrationsQueryKey,
  bc as getListPdCrmIntegrationsQueryOptions,
  mc as getListPdSupportInfoQueryKey,
  gc as getListPdSupportInfoQueryOptions,
  uy as getListPdWebhookEndpointsQueryKey,
  iy as getListPdWebhookEndpointsQueryOptions,
  Uc as getListPdWebhookLogsDeliveryAttemptsQueryKey,
  Nc as getListPdWebhookLogsDeliveryAttemptsQueryOptions,
  Ec as getListPdWebhookLogsEventInfoQueryKey,
  Cc as getListPdWebhookLogsEventInfoQueryOptions,
  Bc as getListPdWebhookLogsEventsQueryKey,
  Rc as getListPdWebhookLogsEventsQueryOptions,
  ci as getListS3OfferCsvsQueryKey,
  yi as getListS3OfferCsvsQueryOptions,
  gy as getListS3PartnerDashboardTableCsvsQueryKey,
  qy as getListS3PartnerDashboardTableCsvsQueryOptions,
  wd as getListSardineDetailsQueryKey,
  Qd as getListSardineDetailsQueryOptions,
  Cn as getListVersionedSchemasQueryKey,
  vn as getListVersionedSchemasQueryOptions,
  ud as getMarkPADAgreementSignedMutationOptions,
  ie as getMarketingGtmToolkit,
  ee as getMarketingIntegrations,
  de as getMarketingParagonToken,
  Wa as getMerchantBankAccounts,
  Ga as getMerchantBankAccountsId,
  wa as getMerchantBankAccountsIdBalances,
  Ty as getMerchantBankApplications,
  Sy as getMerchantBankApplicationsId,
  Lo as getMerchantBankCardAccessToken,
  Ho as getMerchantBankCardCardholderAccessToken,
  Go as getMerchantBankCardCardholders,
  eu as getMerchantBankCardDisputes,
  Co as getMerchantBankCards,
  gu as getMerchantBankExternalAccounts,
  Ku as getMerchantBankExternalAccountsId,
  pu as getMerchantBankExternalAccountsIdPlaidLinkToken,
  yu as getMerchantBankExternalAccountsPlaidLinkToken,
  co as getMerchantBankInterestDetails,
  Sd as getMerchantBankOffers,
  po as getMerchantBankRecipients,
  fo as getMerchantBankRecipientsId,
  to as getMerchantBankTransactions,
  Xa as getMerchantBankTransactionsId,
  ro as getMerchantBankTransferFees,
  oo as getMerchantBankTransferLimits,
  Ac as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryKey,
  Sc as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryOptions,
  jy as getNaics,
  Xu as getOfficerSubsidiaryRecords,
  Tn as getOnboardingBusinessRequirements,
  ps as getOnboardingInfoNeeded,
  ys as getOnboardingStates,
  _t as getOrganization,
  ke as getOwnerIsBeneficialOwner,
  Ks as getOwners,
  rd as getPADAgreement,
  xa as getParafinder,
  Fa as getParafinderBusinessTypes,
  Da as getParafinderCapitalInfo,
  va as getParafinderSummary,
  tt as getPartnerApiKey,
  Nt as getPartnerConfigs,
  Ct as getPartnerPublicConfigs,
  It as getPartnerSyncWebhooks,
  bt as getPartnerUsers,
  vy as getPartnerWidgetConfigs,
  W as getPartners,
  x as getPartnersDoordashAdminUsers,
  Y as getPartnersUnderwriting,
  be as getPatchBeneficialOwnersIdMutationOptions,
  hs as getPatchBusinessIdentitiesIdMutationOptions,
  Xe as getPatchBusinessOfficersIdMutationOptions,
  Cr as getPatchCapitalProductIntentsMutationOptions,
  Jn as getPatchCapitalProductsMutationOptions,
  S as getPatchCashAdvancePausesP1MutationOptions,
  wr as getPatchClearWatchlistHitsIdMutationOptions,
  In as getPatchDataingestionStripeIngestionsMutationOptions,
  Ss as getPatchFlexLoanAgeMutationOptions,
  ws as getPatchFlexLoanFundMutationOptions,
  Is as getPatchFlexLoanOwnershipMutationOptions,
  ha as getPatchLiensIdMutationOptions,
  So as getPatchMerchantBankCardCardholdersIdMutationOptions,
  Bu as getPatchMerchantBankExternalAccountsIdMutationOptions,
  Cu as getPatchMerchantBankExternalBankTransfersIdMutationOptions,
  Wd as getPatchMerchantBankMerchantConfigMutationOptions,
  Eo as getPatchMerchantBankRecipientTransfersIdMutationOptions,
  Mo as getPatchMerchantBankRecipientsIdMutationOptions,
  Ad as getPatchMfaExtendSessionMutationOptions,
  Be as getPatchOwnerIsBeneficialOwnerMutationOptions,
  Ps as getPatchOwnersIdMutationOptions,
  Gt as getPatchPartnerConfigsIdMutationOptions,
  Vt as getPatchPartnerSyncWebhooksIdMutationOptions,
  Rt as getPatchPartnerUsersMutationOptions,
  Ay as getPatchPartnerWidgetConfigsMutationOptions,
  Z as getPatchPartnersIdMutationOptions,
  cs as getPatchPersonaFallbackInquiryMutationOptions,
  ld as getPatchTemplatesMutationOptions,
  qc as getPdOfferUrl,
  kc as getPdUtilityInfo,
  rs as getPlaidInvestigations,
  Cs as getPostAmazonRedirectUrlMutationOptions,
  Yt as getPostAuthTokensMutationOptions,
  fe as getPostBeneficialOwnersMutationOptions,
  h as getPostBusinessAnnotationsMutationOptions,
  Zs as getPostBusinessAuthLinkMutationOptions,
  m as getPostBusinessCoresMutationOptions,
  Bs as getPostBusinessMutationOptions,
  Ve as getPostBusinessOfficersMutationOptions,
  qi as getPostBusinessesNotificationsMutationOptions,
  ir as getPostCapitalProductApplicationMutationOptions,
  Ds as getPostCapitalProductBusinessMutationOptions,
  yr as getPostCapitalProductNetTopUpMutationOptions,
  rr as getPostCapitalProductOfferCollectionMutationOptions,
  Lu as getPostCardCloseAccountMutationOptions,
  Hs as getPostCardsMutationOptions,
  P as getPostCashAdvanceChargeOffsMutationOptions,
  U as getPostCashAdvancePausesMutationOptions,
  Ze as getPostDataingestionOneschemaGenerateJwtMutationOptions,
  qn as getPostDataingestionS3DatasetIntegrationsMutationOptions,
  bn as getPostDataingestionS3OrgDatasetIntegrationsMutationOptions,
  Fn as getPostDataingestionS3RunDatasetIngestionMutationOptions,
  Sn as getPostDataingestionStripeIngestionsMutationOptions,
  Gu as getPostDebtsCheckCompleteMutationOptions,
  Au as getPostDebtsMutationOptions,
  sa as getPostDocumentUploadGroupMutationOptions,
  Hu as getPostFinancialReviewsMutationOptions,
  vd as getPostForgiveCapitalFeeMutationOptions,
  ns as getPostFundingAccountsMutationOptions,
  Ed as getPostManualRepaymentMutationOptions,
  xy as getPostMarkAccountingIntegrationSeenMutationOptions,
  ae as getPostMarketingIntegrationsMutationOptions,
  te as getPostMarketingReviewMutationOptions,
  Ha as getPostMerchantBankAccountsMutationOptions,
  Wy as getPostMerchantBankApplicationsMutationOptions,
  Fs as getPostMerchantBankBusinessMutationOptions,
  Qo as getPostMerchantBankCardActivateMutationOptions,
  Vo as getPostMerchantBankCardApplePayMutationOptions,
  cu as getPostMerchantBankCardDisputeChargebackCreditMutationOptions,
  uu as getPostMerchantBankCardDisputeStateTransitionMutationOptions,
  au as getPostMerchantBankCardDisputesMutationOptions,
  tu as getPostMerchantBankCardGooglePayMutationOptions,
  Jo as getPostMerchantBankCardStateTransitionMutationOptions,
  Do as getPostMerchantBankCardsMutationOptions,
  hu as getPostMerchantBankExternalAccountsMutationOptions,
  Fu as getPostMerchantBankExternalBankTransfersMutationOptions,
  ja as getPostMerchantBankInnerTransfersMutationOptions,
  Ro as getPostMerchantBankRecipientTransfersMutationOptions,
  xo as getPostMerchantBankRecipientsIdPaymentRailsMutationOptions,
  qo as getPostMerchantBankRecipientsMutationOptions,
  td as getPostMfaOtpSendPersonidPhonenumbertypeMutationOptions,
  nd as getPostMfaOtpVerifyPersonidPhonenumbertypeMutationOptions,
  Nd as getPostMfaVerifyAccountDetailsMutationOptions,
  ei as getPostOfficerSubsidiaryRecordsMutationOptions,
  ba as getPostOptInMutationOptions,
  Lt as getPostPartnerSyncWebhooksMutationOptions,
  xt as getPostPartnerUsersMutationOptions,
  H as getPostPartnersMutationOptions,
  _a as getPostRestrictionMutationOptions,
  My as getPostRutterConnectionsMutationOptions,
  Cy as getPostSardineSessionIdMutationOptions,
  Ls as getPostSetupAmazonTopUpMutationOptions,
  gt as getPostSlackAuthorizeMutationOptions,
  Us as getPostSpdMutationOptions,
  ju as getPostTaxRecordsMutationOptions,
  Rd as getPostTemplateTypesMutationOptions,
  _d as getPostTemplatesMutationOptions,
  Oi as getPostTermsOfServiceMarkShownMutationOptions,
  Ar as getPostTriggerRunCelticsChecklistMutationOptions,
  $y as getPostWebhooksPlaidGeneralMutationOptions,
  Bn as getPutDataingestionS3DatasetIngestionConfigsMutationOptions,
  G as getRepaymentPlanParameters,
  Yc as getResendCrmAttemptMutationOptions,
  Ic as getResendWebhookAttemptMutationOptions,
  ia as getRestriction,
  pa as getRestrictionsDetails,
  Sr as getRunOfacScreeningResults,
  hy as getRutterConnections,
  By as getSardineSessionId,
  qt as getSlackChannels,
  cO as getStatement,
  jt as getStatementsV2,
  ey as getSync,
  sy as getSyncAndMaybeMarkFailed,
  md as getTemplateMock,
  Kd as getTemplateSnapshot,
  fd as getTemplateSnapshots,
  Md as getTemplateTypes,
  id as getTemplates,
  Qr as getTermLoanProspectiveRepayments,
  Xr as getUpdateBankAccountInfoMutationOptions,
  ly as getUpdatePdWebhookEndpointMutationOptions,
  yt as linkPartner,
  ai as listApiRequests,
  tn as listDatasetUploads,
  rn as listManualDatasetUploadValidationResults,
  at as listOrganizations,
  hi as listPdBusinessDetailsApplications,
  ki as listPdBusinessDetailsBankInfo,
  xi as listPdBusinessDetailsBusinessInfo,
  vi as listPdBusinessDetailsFundedProductDetails,
  Di as listPdBusinessDetailsFundedProductPaymentProgress,
  Fi as listPdBusinessDetailsFundedProducts,
  Gi as listPdBusinessDetailsOffers,
  wi as listPdBusinessDetailsOwnerInfo,
  Vi as listPdBusinessTableRows,
  ac as listPdCapitalProductApplicationRows,
  dc as listPdCapitalProductOfferRows,
  Zi as listPdCapitalProductRows,
  zc as listPdCrmDeliveryAttempts,
  jc as listPdCrmEventInfo,
  Qc as listPdCrmEvents,
  hc as listPdCrmIntegrations,
  lc as listPdSupportInfo,
  oy as listPdWebhookEndpoints,
  vc as listPdWebhookLogsDeliveryAttempts,
  Fc as listPdWebhookLogsEventInfo,
  xc as listPdWebhookLogsEvents,
  ii as listS3OfferCsvs,
  my as listS3PartnerDashboardTableCsvs,
  Td as listSardineDetails,
  En as listVersionedSchemas,
  od as markPADAgreementSigned,
  Dc as multiWebhookPdWebhookLogsDeliveryAttempts,
  Ke as patchBeneficialOwnersId,
  Os as patchBusinessIdentitiesId,
  je as patchBusinessOfficersId,
  Er as patchCapitalProductIntents,
  Xn as patchCapitalProducts,
  A as patchCashAdvancePausesP1,
  Tr as patchClearWatchlistHitsId,
  Gn as patchDataingestionStripeIngestions,
  As as patchFlexLoanAge,
  Ts as patchFlexLoanFund,
  Gs as patchFlexLoanOwnership,
  Oa as patchLiensId,
  Ao as patchMerchantBankCardCardholdersId,
  xu as patchMerchantBankExternalAccountsId,
  Eu as patchMerchantBankExternalBankTransfersId,
  Ld as patchMerchantBankMerchantConfig,
  Fo as patchMerchantBankRecipientTransfersId,
  ko as patchMerchantBankRecipientsId,
  Dd as patchMfaExtendSession,
  xe as patchOwnerIsBeneficialOwner,
  Ms as patchOwnersId,
  St as patchPartnerConfigsId,
  $t as patchPartnerSyncWebhooksId,
  Bt as patchPartnerUsers,
  Dy as patchPartnerWidgetConfigs,
  J as patchPartnersId,
  is as patchPersonaFallbackInquiry,
  pd as patchTemplates,
  Es as postAmazonRedirectUrl,
  Ht as postAuthTokens,
  qe as postBeneficialOwners,
  xs as postBusiness,
  O as postBusinessAnnotations,
  Js as postBusinessAuthLink,
  l as postBusinessCores,
  $e as postBusinessOfficers,
  gi as postBusinessesNotifications,
  ur as postCapitalProductApplication,
  Ns as postCapitalProductBusiness,
  cr as postCapitalProductNetTopUp,
  nr as postCapitalProductOfferCollection,
  Qu as postCardCloseAccount,
  Vs as postCards,
  M as postCashAdvanceChargeOffs,
  v as postCashAdvancePauses,
  Je as postDataingestionOneschemaGenerateJwt,
  gn as postDataingestionS3DatasetIntegrations,
  Kn as postDataingestionS3OrgDatasetIntegrations,
  Rn as postDataingestionS3RunDatasetIngestion,
  An as postDataingestionStripeIngestions,
  Du as postDebts,
  Su as postDebtsCheckComplete,
  ra as postDocumentUploadGroup,
  Vu as postFinancialReviews,
  Cd as postForgiveCapitalFee,
  es as postFundingAccounts,
  Fd as postManualRepayment,
  Py as postMarkAccountingIntegrationSeen,
  se as postMarketingIntegrations,
  Zt as postMarketingReview,
  Va as postMerchantBankAccounts,
  Ly as postMerchantBankApplications,
  Rs as postMerchantBankBusiness,
  wo as postMerchantBankCardActivate,
  $o as postMerchantBankCardApplePay,
  iu as postMerchantBankCardDisputeChargebackCredit,
  ou as postMerchantBankCardDisputeStateTransition,
  su as postMerchantBankCardDisputes,
  Zo as postMerchantBankCardGooglePay,
  Xo as postMerchantBankCardStateTransition,
  No as postMerchantBankCards,
  Ou as postMerchantBankExternalAccounts,
  Ru as postMerchantBankExternalBankTransfers,
  Ya as postMerchantBankInnerTransfers,
  Bo as postMerchantBankRecipientTransfers,
  go as postMerchantBankRecipients,
  Po as postMerchantBankRecipientsIdPaymentRails,
  Zy as postMfaOtpSendPersonidPhonenumbertype,
  ed as postMfaOtpVerifyPersonidPhonenumbertype,
  Ud as postMfaVerifyAccountDetails,
  ti as postOfficerSubsidiaryRecords,
  Ka as postOptIn,
  Qt as postPartnerSyncWebhooks,
  Pt as postPartnerUsers,
  V as postPartners,
  da as postRestriction,
  ky as postRutterConnections,
  Ey as postSardineSessionId,
  Qs as postSetupAmazonTopUp,
  mt as postSlackAuthorize,
  vs as postSpd,
  Yu as postTaxRecords,
  Bd as postTemplateTypes,
  dd as postTemplates,
  fi as postTermsOfServiceMarkShown,
  Dr as postTriggerRunCelticsChecklist,
  zy as postWebhooksPlaidGeneral,
  zO as publicApiPaths,
  xn as putDataingestionS3DatasetIngestionConfigs,
  Hc as resendCrmAttempt,
  Gc as resendWebhookAttempt,
  jr as updateBankAccountInfo,
  py as updatePdWebhookEndpoint,
  NO as useActivateCard,
  EO as useAmazonRPCPreLogin,
  DO as useAppleWallet,
  TO as useAttemptAmazonBankVerification,
  ff as useAttemptSync,
  pf as useBatchResendWebhookAttempt,
  Im as useBenchmarkGetCapitalProductOwnership,
  Yq as useCountPdBusinessTableRows,
  Zq as useCountPdCapitalProductApplicationRow,
  ef as useCountPdCapitalProductOfferRow,
  Xq as useCountPdCapitalProductRow,
  Xm as useCreateBankAccountInfo,
  bO as useCreateBeneficialOwner,
  Lm as useCreateCapitalProductIntents,
  Em as useCreateCapitalProducts,
  wm as useCreateOfferedCapitalProductFeeDiscount,
  Pl as useCreateOrganization,
  kl as useCreatePartnerApiKey,
  bf as useCreatePdWebhookEndpoint,
  ng as useCreatePlaidInvestigation,
  wO as useCreatePlaidLinkage,
  Sq as useCreateS3OfferCsv,
  xf as useCreateS3PartnerDashboardTableCsv,
  FO as useCreateUnderwritingAttempt,
  BO as useCreateUnverifiedAmazonFundingAccount,
  xO as useCreateUnverifiedFundingAccount,
  IO as useCreateUnverifiedFundingAccountFromPlaidLinkage,
  QO as useCreateZendeskTicket,
  MO as useDeleteBeneficialOwner,
  Jl as useDeleteBeneficialOwnersId,
  cm as useDeleteBusinessOfficersId,
  ll as useDeleteCashAdvancePausesP1,
  Vl as useDeleteMarketingIntegrationsVendor,
  hq as useDeleteMerchantBankExternalAccountsId,
  jg as useDeleteMerchantBankRecipientsId,
  Nl as useDeletePartnerDeleteUsers,
  Tl as useDeletePartnerSyncWebhooksId,
  kf as useDeletePdWebhookEndpoint,
  El as useDeleteSlackChannelsId,
  UO as useDisputeTransaction,
  GO as useFailMicrodeposit,
  Gf as useGetAmazonFundingAccountLock,
  Nq as useGetAnalyticsCubeToken,
  jm as useGetBankAccountInfos,
  nm as useGetBanks,
  jl as useGetBeneficialOwners,
  ul as useGetBusinessAnnotations,
  al as useGetBusinessCores,
  og as useGetBusinessIdentities,
  um as useGetBusinessOfficers,
  om as useGetBusinessTableRowsCard,
  am as useGetBusinessTableRowsLoan,
  sm as useGetBusinessTableRowsMca,
  rm as useGetBusinessTableRowsProductAgnostic,
  vg as useGetBusinessesDailySalesRecords,
  Gq as useGetBusinessesNotifications,
  Kg as useGetBusinessesProducts,
  Nm as useGetCapitalProductActivities,
  Qm as useGetCapitalProductIntents,
  Sm as useGetCapitalProductNetTopUpItemization,
  vm as useGetCapitalProductOfferCollection,
  Rm as useGetCapitalProductProspectiveTermsOfService,
  Tm as useGetCapitalProductRepaymentSchedule,
  Gm as useGetCapitalProductStateDisclosure,
  Fm as useGetCapitalProducts,
  lO as useGetCardAccessToken,
  uO as useGetCardApplication,
  pO as useGetCardBalance,
  OO as useGetCardLifecycleState,
  hO as useGetCardOffer,
  oO as useGetCardOffers,
  _O as useGetCardRepayments,
  yO as useGetCardTransactions,
  iO as useGetCardholder,
  Og as useGetCards,
  cl as useGetCashAdvanceChargeOffs,
  _l as useGetCashAdvancePauses,
  hm as useGetDataingestionS3DatasetIngestionConfigs,
  gm as useGetDataingestionS3DatasetIntegrations,
  mm as useGetDataingestionS3DatasetUploadValidationResults,
  lm as useGetDataingestionS3DatasetUploads,
  fm as useGetDataingestionS3OrgDatasetIntegrations,
  Mm as useGetDataingestionStripeIngestions,
  Bq as useGetDebtChecks,
  Mq as useGetDebts,
  kg as useGetDocumentUploadGroup,
  Pg as useGetDocumentUploadList,
  Fq as useGetFinancialReviews,
  zm as useGetFlexLoanMinimumRepaymentSchedule,
  Zm as useGetFundingAccounts,
  ql as useGetFutureActivities,
  qO as useGetIsWhitelistedForEarlyManualPayment,
  Fg as useGetLiens,
  Hl as useGetMarketingGtmToolkit,
  zl as useGetMarketingIntegrations,
  Yl as useGetMarketingParagonToken,
  Ig as useGetMerchantBankAccounts,
  Sg as useGetMerchantBankAccountsId,
  Gg as useGetMerchantBankAccountsIdBalances,
  Df as useGetMerchantBankApplications,
  Nf as useGetMerchantBankApplicationsId,
  oq as useGetMerchantBankCardAccessToken,
  iq as useGetMerchantBankCardCardholderAccessToken,
  sq as useGetMerchantBankCardCardholders,
  dq as useGetMerchantBankCardDisputes,
  eq as useGetMerchantBankCards,
  qq as useGetMerchantBankExternalAccounts,
  Oq as useGetMerchantBankExternalAccountsId,
  gq as useGetMerchantBankExternalAccountsIdPlaidLinkToken,
  mq as useGetMerchantBankExternalAccountsPlaidLinkToken,
  $g as useGetMerchantBankInterestDetails,
  nO as useGetMerchantBankOffers,
  Vg as useGetMerchantBankRecipients,
  Yg as useGetMerchantBankRecipientsId,
  Lg as useGetMerchantBankTransactions,
  Qg as useGetMerchantBankTransactionsId,
  Wg as useGetMerchantBankTransferFees,
  zg as useGetMerchantBankTransferLimits,
  mO as useGetMicrodepositLinkToken,
  If as useGetNaics,
  vq as useGetOfficerSubsidiaryRecords,
  Bm as useGetOnboardingBusinessRequirements,
  ag as useGetOnboardingInfoNeeded,
  sg as useGetOnboardingStates,
  KO as useGetOptIns,
  fO as useGetOptOutEligibility,
  Bl as useGetOrganization,
  tm as useGetOwnerIsBeneficialOwner,
  ig as useGetOwners,
  Qf as useGetPADAgreement,
  Ug as useGetParafinder,
  Ng as useGetParafinderBusinessTypes,
  Ag as useGetParafinderCapitalInfo,
  Dg as useGetParafinderSummary,
  bl as useGetPartnerApiKey,
  Al as useGetPartnerConfigs,
  Dl as useGetPartnerPublicConfigs,
  Gl as useGetPartnerSyncWebhooks,
  Cl as useGetPartnerUsers,
  vf as useGetPartnerWidgetConfigs,
  fl as useGetPartners,
  dl as useGetPartnersDoordashAdminUsers,
  hl as useGetPartnersUnderwriting,
  sf as useGetPdOfferUrl,
  of as useGetPdUtilityInfo,
  dO as useGetPendingCardTransactions,
  eg as useGetPlaidInvestigations,
  aO as useGetPlaidLinkToken,
  gl as useGetRepaymentPlanParameters,
  xg as useGetRestriction,
  Rg as useGetRestrictionsDetails,
  Vm as useGetRunOfacScreeningResults,
  Bf as useGetRutterConnections,
  Ef as useGetSardineSessionId,
  gO as useGetShowTopUpOffer,
  Fl as useGetSlackChannels,
  Ll as useGetStatementsV2,
  Of as useGetSync,
  hf as useGetSyncAndMaybeMarkFailed,
  Vf as useGetTemplateMock,
  Yf as useGetTemplateSnapshot,
  Hf as useGetTemplateSnapshots,
  jf as useGetTemplateTypes,
  Wf as useGetTemplates,
  Ym as useGetTermLoanProspectiveRepayments,
  AO as useGooglePay,
  SO as useInitiateMicrodeposit,
  xl as useLinkPartner,
  Dq as useListApiRequests,
  _m as useListDatasetUploads,
  pm as useListManualDatasetUploadValidationResults,
  Ml as useListOrganizations,
  wq as useListPdBusinessDetailsApplications,
  Qq as useListPdBusinessDetailsBankInfo,
  Lq as useListPdBusinessDetailsBusinessInfo,
  zq as useListPdBusinessDetailsFundedProductDetails,
  $q as useListPdBusinessDetailsFundedProductPaymentProgress,
  Wq as useListPdBusinessDetailsFundedProducts,
  Vq as useListPdBusinessDetailsOffers,
  Hq as useListPdBusinessDetailsOwnerInfo,
  jq as useListPdBusinessTableRows,
  tf as useListPdCapitalProductApplicationRows,
  nf as useListPdCapitalProductOfferRows,
  Jq as useListPdCapitalProductRows,
  mf as useListPdCrmDeliveryAttempts,
  qf as useListPdCrmEventInfo,
  lf as useListPdCrmEvents,
  af as useListPdCrmIntegrations,
  rf as useListPdSupportInfo,
  Kf as useListPdWebhookEndpoints,
  yf as useListPdWebhookLogsDeliveryAttempts,
  cf as useListPdWebhookLogsEventInfo,
  uf as useListPdWebhookLogsEvents,
  Aq as useListS3OfferCsvs,
  Pf as useListS3PartnerDashboardTableCsvs,
  rO as useListSardineDetails,
  km as useListVersionedSchemas,
  Lf as useMarkPADAgreementSigned,
  df as useMultiWebhookPdWebhookLogsDeliveryAttempts,
  WO as useOptIn,
  LO as useOptOut,
  kO as usePatchBeneficialOwner,
  Zl as usePatchBeneficialOwnersId,
  ug as usePatchBusinessIdentitiesId,
  ym as usePatchBusinessOfficersId,
  Wm as usePatchCapitalProductIntents,
  Cm as usePatchCapitalProducts,
  ml as usePatchCashAdvancePausesP1,
  Hm as usePatchClearWatchlistHitsId,
  xm as usePatchDataingestionStripeIngestions,
  mg as usePatchFlexLoanAge,
  qg as usePatchFlexLoanFund,
  gg as usePatchFlexLoanOwnership,
  Eg as usePatchLiensId,
  rq as usePatchMerchantBankCardCardholdersId,
  Kq as usePatchMerchantBankExternalAccountsId,
  kq as usePatchMerchantBankExternalBankTransfersId,
  sO as usePatchMerchantBankMerchantConfig,
  tq as usePatchMerchantBankRecipientTransfersId,
  Xg as usePatchMerchantBankRecipientsId,
  eO as usePatchMfaExtendSession,
  em as usePatchOwnerIsBeneficialOwner,
  cg as usePatchOwnersId,
  Sl as usePatchPartnerConfigsId,
  wl as usePatchPartnerSyncWebhooksId,
  Ul as usePatchPartnerUsers,
  Uf as usePatchPartnerWidgetConfigs,
  Kl as usePatchPartnersId,
  rg as usePatchPersonaFallbackInquiry,
  $f as usePatchTemplates,
  _g as usePostAmazonRedirectUrl,
  Ql as usePostAuthTokens,
  Xl as usePostBeneficialOwners,
  yg as usePostBusiness,
  il as usePostBusinessAnnotations,
  bg as usePostBusinessAuthLink,
  ol as usePostBusinessCores,
  im as usePostBusinessOfficers,
  Iq as usePostBusinessesNotifications,
  Dm as usePostCapitalProductApplication,
  lg as usePostCapitalProductBusiness,
  Am as usePostCapitalProductNetTopUp,
  Um as usePostCapitalProductOfferCollection,
  Rq as usePostCardCloseAccount,
  hg as usePostCards,
  yl as usePostCashAdvanceChargeOffs,
  pl as usePostCashAdvancePauses,
  dm as usePostDataingestionOneschemaGenerateJwt,
  qm as usePostDataingestionS3DatasetIntegrations,
  Om as usePostDataingestionS3OrgDatasetIntegrations,
  bm as usePostDataingestionS3RunDatasetIngestion,
  Pm as usePostDataingestionStripeIngestions,
  Pq as usePostDebts,
  xq as usePostDebtsCheckComplete,
  Mg as usePostDocumentUploadGroup,
  Eq as usePostFinancialReviews,
  Zf as usePostForgiveCapitalFee,
  tg as usePostFundingAccounts,
  Jf as usePostManualRepayment,
  Ff as usePostMarkAccountingIntegrationSeen,
  $l as usePostMarketingIntegrations,
  Wl as usePostMarketingReview,
  Tg as usePostMerchantBankAccounts,
  Af as usePostMerchantBankApplications,
  dg as usePostMerchantBankBusiness,
  aq as usePostMerchantBankCardActivate,
  uq as usePostMerchantBankCardApplePay,
  lq as usePostMerchantBankCardDisputeChargebackCredit,
  pq as usePostMerchantBankCardDisputeStateTransition,
  _q as usePostMerchantBankCardDisputes,
  yq as usePostMerchantBankCardGooglePay,
  cq as usePostMerchantBankCardStateTransition,
  nq as usePostMerchantBankCards,
  fq as usePostMerchantBankExternalAccounts,
  bq as usePostMerchantBankExternalBankTransfers,
  wg as usePostMerchantBankInnerTransfers,
  Zg as usePostMerchantBankRecipientTransfers,
  Hg as usePostMerchantBankRecipients,
  Jg as usePostMerchantBankRecipientsIdPaymentRails,
  Tf as usePostMfaOtpSendPersonidPhonenumbertype,
  wf as usePostMfaOtpVerifyPersonidPhonenumbertype,
  tO as usePostMfaVerifyAccountDetails,
  Uq as usePostOfficerSubsidiaryRecords,
  Cg as usePostOptIn,
  Il as usePostPartnerSyncWebhooks,
  vl as usePostPartnerUsers,
  Ol as usePostPartners,
  Bg as usePostRestriction,
  Rf as usePostRutterConnections,
  Cf as usePostSardineSessionId,
  fg as usePostSetupAmazonTopUp,
  Rl as usePostSlackAuthorize,
  pg as usePostSpd,
  Cq as usePostTaxRecords,
  Xf as usePostTemplateTypes,
  zf as usePostTemplates,
  Tq as usePostTermsOfServiceMarkShown,
  $m as usePostTriggerRunCelticsChecklist,
  Sf as usePostWebhooksPlaidGeneral,
  Km as usePutDataingestionS3DatasetIngestionConfigs,
  gf as useResendCrmAttempt,
  _f as useResendWebhookAttempt,
  PO as useSubmitKYC,
  Jm as useUpdateBankAccountInfo,
  CO as useUpdateBoostCompletion,
  vO as useUpdateCardholder,
  Mf as useUpdatePdWebhookEndpoint,
  RO as useUploadBankStatements
};
