import styled from 'styled-components'
type LogoProps = {
  variant: 'iconOnly' | 'default'
  color: string
}
const Logo = ({ color, variant = 'default' }: LogoProps) => (
  <svg
    fill="none"
    viewBox="0 0 468 120"
    width="108"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="parafin-logo"
  >
    <G variant={variant} color={color}>
      <path d="m195.182 27.1636h-26.182v66.4363h10.909v-24.109h15.273c13.854 0 22.963-8.3455 22.963-21.1637s-9.109-21.1636-22.963-21.1636zm-.927 32.8363h-14.346v-23.3454h14.4c7.8 0 12.709 4.4727 12.709 11.6727s-5.018 11.6727-12.818 11.6727zm65.89-7.8545c-3.109-4.4727-8.236-7.6909-15.872-7.6909-13.2 0-22.964 9.8727-22.964 24.8727s9.764 24.8728 22.964 24.8728c7.582 0 12.709-3.6 15.872-7.9637l1.146 7.4182h9v-48.6546h-8.891l-1.255 7.091zm-14.127 33.3818c-8.563 0-14.127-6.6545-14.127-16.2545s5.618-16.1455 14.127-16.1455 14.127 6.7091 14.127 16.3091-5.618 16.0364-14.127 16.0364zm102.218-33.3818c-3.109-4.4727-8.236-7.6909-15.872-7.6909-13.2 0-22.964 9.8727-22.964 24.8727s9.764 24.8728 22.964 24.8728c7.581 0 12.709-3.6 15.872-7.9637l1.146 7.4182h9v-48.6546h-8.891l-1.255 7.091zm-14.127 33.3818c-8.564 0-14.127-6.6545-14.127-16.2545s5.618-16.1455 14.127-16.1455 14.127 6.7091 14.127 16.3091-5.618 16.0364-14.127 16.0364zm-29.182-40.5273h2.291v9.6h-4.527c-9.109 0-12.055 7.091-12.055 14.6182v24.3818h-10.254v-48.6h9.109l1.145 7.3091c2.455-4.0909 6.382-7.3091 14.346-7.3091zm106.964-12.9818c0 3.6-2.727 6.1637-6.273 6.1637-3.545 0-6.273-2.5637-6.273-6.1637s2.728-6.1636 6.273-6.1636c3.546 0 6.273 2.5636 6.273 6.1636zm53.945 34.5273v27.0545h-10.363v-26.1818c0-9.2182-3.873-14.1272-11.564-14.1272-8.182 0-12.982 6-12.982 15.8181v24.4909h-10.254v-48.6h8.945l1.146 6.3819c3.054-3.8182 7.691-6.9273 15.545-6.9273 10.746 0 19.527 5.8909 19.527 22.0909zm-83.291-21.5455h28.2v48.6h-10.363v-39.5454h-17.837v39.5454h-10.363v-39.5454h-8.455v-9h8.455v-4.8546c0-8.5636 4.2-12.9818 13.582-12.9818h8.618v9h-7.2c-3.327 0-4.637 1.4182-4.637 4.7455v4.0909z" />
    </G>
    <g
      fill={color}
      style={{
        transition: 'fill 0.4s',
      }}
    >
      <path d="m63.226 38.514 3.837-38.213982c-2.0147-.2014552-4.0383-.30158138-6.063-.29999955-1.95 0-3.878.09299995-5.784.27899955l1.842 18.785982c1.323-.477 2.679-.9 4.059-1.242l-.024 20.58c.72-.003 1.434.039 2.133.111z" />
      <path d="m43.453 27c.97-.834 1.975-1.624 3.015-2.37l6.348 15.372c.656-.268 1.328-.502 2.016-.702l-11.079-36.78003c-3.7913 1.13913-7.4603 2.65154-10.953 4.515z" />
      <path d="m74.266 16.572c1.5541.0858 3.1024.256 4.638.51l-9.555 22.992c.66.276 1.302.585 1.926.921l18.168-33.83695c-3.4839-1.87844-7.1459-3.40595-10.932-4.56z" />
      <path d="m36.169 35.0429c-.662.964-1.293 1.946-1.893 2.946l-19.617-16.11c2.5185-3.0571 5.3321-5.8586 8.4-8.364l24.3 29.73c-.5538.4499-1.0845.9275-1.59 1.431z" />
      <path d="m76.324 44.778 20.445-20.4c-1.3701-.9637-2.7955-1.8462-4.269-2.643l6.627-8.073c3.054 2.5183 5.851 5.332 8.352 8.4l-29.73 24.3c-.45-.552-.925-1.08-1.425-1.584z" />
      <path d="m81.7 53.787 36.777-11.079c-1.139-3.7876-2.651-7.4526-4.515-10.941l-6.417 3.426c1.003 1.4437 1.917 2.9464 2.739 4.5l-29.307 12.087c.2749.6563.5163 1.3262.723 2.007z" />
      <path d="m96.676 95.7c1.3929-.9781 2.7281-2.0359 3.999-3.168l6.696 5.535c-2.511 3.055-5.315 5.857-8.373 8.364l-24.3-29.727c.549-.453 1.077-.93 1.578-1.434z" />
      <path d="m115.3 60.06v-.06c-.001-1.7608-.108-3.52-.321-5.268l5.736-.564c.186 1.923.285 3.873.285 5.847 0 2.022-.1 4.017-.3 5.985l-38.208-3.849c.069-.699.108-1.41.108-2.127z" />
      <path d="m36.124 84.8999 9.621-9.6c-.504-.504-.984-1.032-1.44-1.584l-29.727 24.3c2.511 3.0681 5.3175 5.8811 8.379 8.4001l15.33-18.6631c-.758-.93-1.479-1.881-2.163-2.853z" />
      <path d="m28.3 59.964v.036c0 1.077.042 2.145.132 3.201l-27.132 2.658c-.201-1.926-.3-3.882-.3-5.859 0-2.026.1-4.026.3-6l38.208 3.822c-.069.705-.108 1.419-.108 2.142z" />
      <path d="m31.3 75.9c-.4-1.032-.766-2.082-1.098-3.15l10.845-4.476c-.273-.657-.513-1.332-.723-2.019l-36.777 11.076c1.14156 3.7961 2.66013 7.4684 4.533 10.962z" />
      <path d="m78.799 102.936c1.5687-.252 3.1222-.592 4.653-1.017l5.865 10.989c-3.4868 1.87-7.1508 3.388-10.938 4.533l-11.082-36.777c.684-.207 1.356-.45 2.01-.72z" />
      <path d="m112.414 75.5999c-.633 1.6508-1.367 3.2609-2.199 4.821l-29.256-12.156c-.2678.6556-.5682 1.2974-.9 1.923l33.84 18.171c1.87-3.4851 3.391-7.147 4.539-10.932z" />
      <path d="m65.287 103.005 1.635 16.695c-1.948.2-3.922.3-5.922.3s-3.974-.1-5.922-.3l3.822-38.211c.699.066 1.395.102 2.1.102h.048l-.024 20.553c1.4054.359 2.8283.647 4.263.861z" />
      <path d="m46.387 95.313c1.07.764 2.17 1.481 3.3 2.151l-6.069 19.974c-3.7919-1.145-7.4601-2.665-10.95-4.539l18.156-33.825c.627.321 1.272.627 1.935.9z" />
      <path d="m30.22 47.1811c-.32 1.048-.603 2.11-.849 3.186l-25.785-7.833c1.1503-3.7932 2.67793-7.4616 4.56-10.95l33.837 18.162c-.3395.629-.6469 1.2749-.921 1.935z" />
    </g>
  </svg>
)

const G = styled.g<LogoProps>`
  fill: ${({ color }) => color};
  opacity: 1;
  transition-property: opacity, fill;
  transition-duration: 0.4s;
  @media (max-width: 1208px) {
    ${({ variant }) => variant === 'iconOnly' && 'opacity: 0;'}
  }
`

export default Logo
