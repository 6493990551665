import { useContext, createContext, useEffect, ReactNode } from 'react'
import { Auth0Provider, IdToken } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { decodeToken, isExpired } from 'react-jwt'
import { AUTH_URL, AUTH_ID, BASE_URL } from '@/parafin.config'
import { FullScreenLoading } from '@/src/ui/components/Loading'
import { ErrorDisplay } from '@/src/components/generic/ErrorDisplay'
import { SANDBOX_HEADER } from '@/src/providers/core'
import { PartnerUser, UserRole } from '@parafin/medici-api'

type AuthContextType = {
  metadata: {
    organization_id: string | undefined
    partner_id: string | undefined
    platform_type: 'partner' | 'organization'
    role: UserRole
    userId: string
  }
  user: PartnerUser
  token: string
  authHeader: string
  claims: IdToken
  queryOptions: (
    sandbox: boolean,
    queryKey?: any
  ) => {
    axios: {
      baseURL: string
      headers: { authorization: string }
    }
    query?: {
      queryKey: string[]
    }
  }
  logout: () => void
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const onRedirect = ({ returnTo }: any) => {
    history.pushState(null, '', returnTo)
  }

  return (
    <Auth0Provider
      domain={AUTH_URL}
      clientId={AUTH_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirect}
    >
      <AuthProviderInternal>{children}</AuthProviderInternal>
    </Auth0Provider>
  )
}

const AuthProviderInternal = ({ children }: { children: ReactNode }) => {
  const { token, isLoading, isError, logout } = useFetchAuth()

  if (isLoading || !token) return <FullScreenLoading />
  if (isError) return <ErrorDisplay />

  const user_details = (token as any)[BASE_URL + '/user_authorization']
  const role = user_details?.app_metadata?.role
  const user = {
    name: token.name ?? '',
    email: token.email ?? '',
    role,
  }
  const authHeader = `Bearer ${token.__raw}`
  // this needs to be its own hook
  const queryOptions = (sandbox: boolean, queryKey?: string[]) => ({
    axios: {
      baseURL: BASE_URL,
      headers: {
        ...{ authorization: authHeader },
        ...(sandbox ? { [SANDBOX_HEADER]: '1' } : {}),
      },
      paramsSerializer: {
        indexes: null,
      },
    },
    ...(queryKey
      ? {
          query: {
            queryKey,
          },
        }
      : {}),
  })

  return (
    <AuthContext.Provider
      value={{
        metadata: user_details?.app_metadata,
        user,
        token: token.__raw,
        authHeader,
        claims: token,
        queryOptions,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) throw new Error('Not in AuthProvider')

  return context
}

const useFetchAuth = () => {
  const urlToken = getTokenFromUrl()

  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    getIdTokenClaims,
    logout,
  } = useAuth0()

  const authQuery = useQuery({
    queryKey: ['auth-states'],
    queryFn: async () => {
      const claims = await getIdTokenClaims()
      if (!claims) {
        document.location.reload()
        return null
      }

      return claims
    },
    enabled: isAuthenticated,
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60 * 12,
    refetchOnWindowFocus: true,
  })

  useEffect(() => {
    if (!urlToken && !isAuthenticated && !isLoading) {
      loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      })
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, urlToken, authQuery.error])

  return {
    token: urlToken ?? authQuery.data ?? null,
    isLoading: !urlToken && (isLoading || authQuery.isLoading),
    isError: !!error || !!authQuery.error,
    logout: () => logout({ returnTo: window.location.origin }),
  }
}

const getTokenFromUrl = () => {
  const search = new URLSearchParams(window.location.search)
  const token = search.get('token')
  if (!token) return null
  const decoded = decodeToken(token) as IdToken | null
  const expired = isExpired(token)
  if (!decoded || expired) return null
  const userDetails = decoded[BASE_URL + '/user_authorization']
  const meta = userDetails?.app_metadata
  if (meta?.userId && meta?.role && decoded.aud === AUTH_ID) {
    return { ...decoded, __raw: token }
  } else {
    return null
  }
}
