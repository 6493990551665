import '@/src/ui/theme/fonts.css'
import '@/src/ui/theme/global.css'

export type ThemeContextType = {
  colors: {
    // LEGACY DO NOT ADD
    white: string
    mono100: string
    mono200: string
    mono300: string
    mono400: string
    mono500: string
    mono600: string
    mono700: string
    mono800: string
    mono900: string
    black: string
    accent: string // Totally-blue
    accent50: string // Royal-blue
    accent100: string // Basil
    accent200: string // Mint
    accent300: string // Kale
    accent400: string // Peach
    accent500: string // Reed
    accent600: string // Toffee
    accent700: string // Moon
    positive100: string
    positive200: string
    positive300: string
    positive400: string
    positive500: string
    positive600: string
    negative100: string // Paler than Reed
    //END LEGACY

    black100: string
    black90: string
    black80: string
    black70: string
    black60: string
    black40: string
    black30: string
    black20: string
    black10: string
    black0: string

    royalBlue100: string
    royalBlue90: string
    royalBlue80: string
    royalBlue70: string
    royalBlue60: string
    royalBlue50: string
    royalBlue40: string
    royalBlue30: string
    royalBlue20: string
    royalBlue10: string

    totallyBlue100: string
    totallyBlue90: string
    totallyBlue80: string
    totallyBlue70: string
    totallyBlue60: string
    totallyBlue50: string
    totallyBlue40: string
    totallyBlue30: string
    totallyBlue20: string
    totallyBlue10: string

    reed140: string
    reed100: string
    reed90: string
    reed80: string
    reed70: string
    reed60: string
    reed50: string
    reed40: string
    reed30: string
    reed20: string
    reed10: string

    peach100: string
    peach90: string
    peach80: string
    peach70: string
    peach60: string
    peach50: string
    peach40: string
    peach30: string
    peach20: string
    peach10: string

    kale100: string
    kale90: string
    kale80: string
    kale70: string
    kale60: string
    kale50: string
    kale40: string
    kale30: string
    kale20: string
    kale10: string

    basil100: string
    basil90: string
    basil80: string
    basil70: string
    basil60: string
    basil50: string
    basil40: string
    basil30: string
    basil20: string
    basil10: string

    mint100: string
    mint90: string
    mint80: string
    mint70: string
    mint60: string
    mint50: string
    mint40: string
    mint30: string
    mint20: string
    mint10: string

    mustard100: string
    mustard90: string
    mustard80: string
    mustard70: string
    mustard60: string
    mustard50: string
    mustard40: string
    mustard30: string
    mustard20: string
    mustard10: string

    banana100: string
    banana90: string
    banana80: string
    banana70: string
    banana60: string
    banana50: string
    banana40: string
    banana30: string
    banana20: string
    banana10: string

    magenta100: string
    magenta90: string
    magenta80: string
    magenta70: string
    magenta60: string
    magenta50: string
    magenta40: string
    magenta30: string
    magenta20: string
    magenta10: string
  }
}

const useTheme = () => {
  const theme: ThemeContextType = {
    colors: {
      // LEGACY COLORS DO NOT ADD
      white: '#FFFFFF',
      mono100: '#FDFDFD',
      mono200: '#F7F8F7',
      mono300: '#E9EBEC',
      mono400: '#D2D4D8',
      mono500: '#D2D4D8',
      mono600: '#AFAFAF',
      mono700: '#A1A2A4',
      mono800: '#707274',
      mono900: '#343535',
      black: '#000000',
      accent: '#1579FB', // Totally-blue
      accent50: '#085ECE', // Royal-blue
      accent100: '#009568', // Basil
      accent200: '#BBECD3', // Mint
      accent300: '#00704F', // Kale
      accent400: '#F4AC8C', // Peach
      accent500: '#EF4B28', // Reed
      accent600: '#8E541D', // Toffee
      accent700: '#FFF5C1', // Moon
      positive100: '#985DF6',
      positive200: '#9FDD1A',
      positive300: '#8BE4DA',
      positive400: '#FAD82B',
      positive500: '#345D89',
      positive600: '#E67DC9',
      negative100: '#FFF0ED', // Paler than Reed
      // END LEGACY

      black100: '#000000',
      black90: '#343535',
      black80: '#707274',
      black60: '#AFAFAF',
      black70: '#A1A2A4',
      black40: '#D2D4D8',
      black30: '#E9EBEC',
      black20: '#F7F8F7',
      black10: '#FDFDFD',
      black0: '#FFFFFF',

      royalBlue100: '#085ECE',
      royalBlue90: '#216ED3',
      royalBlue80: '#397ED8',
      royalBlue70: '#528EDD',
      royalBlue60: '#6B9EE2',
      royalBlue50: '#83AEE7',
      royalBlue40: '#9CBFEB',
      royalBlue30: '#B5CFF0',
      royalBlue20: '#CEDFF5',
      royalBlue10: '#E6EFFA',

      totallyBlue100: '#1579FB',
      totallyBlue90: '#2C86FB',
      totallyBlue80: '#4494FC',
      totallyBlue70: '#5BA1FC',
      totallyBlue60: '#73AFFD',
      totallyBlue50: '#8ABCFD',
      totallyBlue40: '#A1C9FD',
      totallyBlue30: '#B9D7FE',
      totallyBlue20: '#D0E4FE',
      totallyBlue10: '#E8F2FF',

      reed140: '#8F2D18',
      reed100: '#EF4B28',
      reed90: '#F15D3E',
      reed80: '#F26F53',
      reed70: '#F48169',
      reed60: '#F5937E',
      reed50: '#F7A593',
      reed40: '#F9B7A9',
      reed30: '#FAC9BE',
      reed20: '#FCDBD4',
      reed10: '#FFF0ED',

      peach100: '#F4AC8C',
      peach90: '#F5B497',
      peach80: '#F6BDA3',
      peach70: '#F7C5AF',
      peach60: '#F8CDBA',
      peach50: '#F9D5C5',
      peach40: '#FBDED1',
      peach30: '#FCE6DD',
      peach20: '#FDEEE8',
      peach10: '#FEF7F3',

      kale100: '#00704F',
      kale90: '#1A7E61',
      kale80: '#338D72',
      kale70: '#4D9B84',
      kale60: '#66A995',
      kale50: '#80B7A7',
      kale40: '#99C6B9',
      kale30: '#B2D4CA',
      kale20: '#CCE2DC',
      kale10: '#E5F1ED',

      basil100: '#009568',
      basil90: '#1A9F77',
      basil80: '#33AA86',
      basil70: '#4DB595',
      basil60: '#66BFA5',
      basil50: '#80CAB4',
      basil40: '#99D5C3',
      basil30: '#B3DFD2',
      basil20: '#CCEAE1',
      basil10: '#E6F4F0',

      mint100: '#BBECD3',
      mint90: '#C2EED7',
      mint80: '#C9F0DC',
      mint70: '#CFF2E0',
      mint60: '#D6F4E5',
      mint50: '#DDF5E9',
      mint40: '#E4F7ED',
      mint30: '#EBF9F2',
      mint20: '#F1FBF6',
      mint10: '#F8FDFB',

      mustard100: '#8E541D',
      mustard90: '#9A6534',
      mustard80: '#A5764A',
      mustard70: '#B08761',
      mustard60: '#BB9877',
      mustard50: '#C7A98E',
      mustard40: '#D2BAA5',
      mustard30: '#DDCCBB',
      mustard20: '#E8DDD2',
      mustard10: '#F4EEE8',

      banana100: '#FFF5C1',
      banana90: '#FFF6C8',
      banana80: '#FFF7CE',
      banana70: '#FFF8D4',
      banana60: '#FFF9DA',
      banana50: '#FFFAE0',
      banana40: '#FFFBE6',
      banana30: '#FFFCED',
      banana20: '#FFFDF3',
      banana10: '#FFFEF9',

      magenta100: '#FB3BFF',
      magenta90: '#FB4FFF',
      magenta80: '#FC62FF',
      magenta70: '#FC76FF',
      magenta60: '#FD89FF',
      magenta50: '#FD9DFF',
      magenta40: '#FDB1FF',
      magenta30: '#FEC4FF',
      magenta20: '#FED8FF',
      magenta10: '#FFEBFF',
    },
  }
  return { theme }
}

export default useTheme
