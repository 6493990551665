import {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  createContext,
  ReactNode,
} from 'react'
import styled from 'styled-components'
import { Breadcrumb } from '@/src/components/frame/Breadcrumbs'
import { Sidebar } from '@/src/components/frame/Sidebar'
import { Header } from '@/src/components/frame/Header'
import { useCore } from '@/src/providers/core'
import { OrganizationDropdown } from '../components/generic/PartnerSwitcher'

type FrameContextType = {
  setHeading: (newValue: string | Breadcrumb[]) => void
}

const FrameContext = createContext<FrameContextType | undefined>(undefined)

export const FrameProvider = ({ children }: { children: ReactNode }) => {
  const [heading, setHeading] = useState<string | Breadcrumb[]>('')
  const { partners, partner, setPartnerId, organization, sandbox } = useCore()

  useEffect(() => {
    if (heading) {
      window.document.title = transformHeadingToString(heading)
    }
  }, [heading])

  return (
    <FrameContext.Provider value={{ setHeading }}>
      {sandbox && <SandboxWrapper>Sandbox</SandboxWrapper>}
      <MainWrapper sandboxMode={sandbox}>
        <Sidebar />
        <ContentWrapper>
          <Header heading={heading}>
            {organization !== undefined && heading && (
              <OrganizationDropdown
                partners={partners}
                organization={organization}
                partner={partner}
                setPartnerId={setPartnerId}
              />
            )}
          </Header>
          <div data-main-target>{children}</div>
        </ContentWrapper>
      </MainWrapper>
    </FrameContext.Provider>
  )
}

type FrameContextProps = {
  heading?: string | Breadcrumb[]
}

export const useFrame = (props?: FrameContextProps) => {
  const context = useContext(FrameContext)
  if (context === undefined) throw new Error('Not in FrameProvider')

  const { setHeading } = context

  useLayoutEffect(() => {
    props?.heading && setHeading(props.heading)
    return () => {
      setHeading('')
    }
  }, [props, setHeading])

  return context
}

const transformHeadingToString = (crumbs: string | Breadcrumb[]) => {
  if (typeof crumbs === 'string') return crumbs

  return crumbs.reduce((a, b, index) => {
    return `${a}${index > 0 ? ' >' : ''} ${b.name}`
  }, '')
}

const SandboxWrapper = styled.div`
  background: ${({ theme }) => theme.colors.accent700};
  color: ${({ theme }) => theme.colors.accent600};
  font-size: 14px;
  height: 28px;
  left: 0;
  line-height: 28px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 1000;
`

const MainWrapper = styled.div<{ sandboxMode: boolean }>`
  color: ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.white};
  padding-top: ${({ sandboxMode }) => (sandboxMode ? '28px' : '0')};
  display: flex;
`

const ContentWrapper = styled.div`
  padding-left: 64px;
  width: 100%;

  @media (min-width: 1210px) {
    padding-left: 240px;
  }
`
