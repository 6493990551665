import { ReactNode } from 'react'
import { ExperimentProvider as BaseExperimentProvider } from '@parafin/experimentation'
import { ENVIRONMENT, STATSIG_KEY } from '@/parafin.config'
import { useAuth } from './auth'
import { useCore } from './core'
import { FullScreenLoading } from '../ui/components/Loading'

export const STATSIG_GATES = {
  showWebhookEndpoints: 'pd-new-webhook-endpoints',
}

export const ExperimentProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth()
  const { orgOrPartner, organization } = useCore()

  return (
    <BaseExperimentProvider
      statsigKey={STATSIG_KEY}
      environment={ENVIRONMENT}
      userID={user.email}
      partnerSlug={orgOrPartner.slug}
      organizationSlugs={organization ? [organization.slug] : []}
      loadingComponent={<FullScreenLoading />}
    >
      {children}
    </BaseExperimentProvider>
  )
}
