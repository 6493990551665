import { ReactNode, useCallback, useRef } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AxiosError } from 'axios'
import { useErrorBoundary } from '@parafin/error-handling'
import { toast } from 'react-hot-toast'

export const QueryProvider = ({ children }: { children: ReactNode }) => {
  const { reportError } = useErrorBoundary()

  const checkUseErrorBoundary = useCallback((error: any) => {
    if (error instanceof AxiosError) {
      const { status } = error.request

      if (status === 404) {
        return false
      }

      if (status === 401) {
        showToast('Your session has expired. Please refresh the page.')
        return false
      }

      if (status >= 500) {
        showToast('Something went wrong on our end. We are looking into it!')
        reportError(error)
        return false
      }
    }

    return true
  }, [])

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 4 * 60 * 60 * 1000,
          retry: false,
          refetchOnWindowFocus: false,
          useErrorBoundary: checkUseErrorBoundary,
        },
        mutations: {
          useErrorBoundary: checkUseErrorBoundary,
        },
      },
    })
  )

  return (
    <QueryClientProvider client={queryClient.current}>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

const showToast = (message: string) => {
  setTimeout(() => {
    toast.error(message, {
      duration: 5000,
      position: 'top-center',
      id: message,
    })
    // 50ms delay prevents a React changing state of unmounted component bug
    // when trying to render the toast. See: https://reactjs.org/link/setstate-in-render
  }, 50)
}
