import { useEffect } from 'react'
import { Main } from './styles'
import { Typography } from '@/src/ui/components/Typography'
import { Space } from '../../../components/ui/Space'
import { BaseError, useErrorBoundary } from '@parafin/error-handling'

class PageNotFoundError extends BaseError {
  constructor(url?: string) {
    super(`Page not found: ${url}`, 'error')
  }
}

const PageNotFound = () => {
  const { reportError } = useErrorBoundary()
  const url = window.location.href

  useEffect(() => {
    document.title = '404: Page not found'
    reportError(new PageNotFoundError(url))
  }, [])

  return (
    <Main>
      <Typography variant="heading" color="mono700">
        404: Page not found
      </Typography>
      <Space amount="32px" />
      <Typography color="mono700">
        The page you are trying to view does not exist
      </Typography>
    </Main>
  )
}

export default PageNotFound
