import React, { useState } from 'react'
import { Icon } from '../Icon'
import { Partner } from '@parafin/medici-api'
import styled from 'styled-components'
import { Center } from '../Flex'

type PartnerLogoProps = {
  variant?: 'logo' | 'icon'
  partner?: Partner
  icon?: React.ReactNode
}

export const PartnerLogo = ({
  partner,
  icon,
  variant = 'icon',
}: PartnerLogoProps) => {
  const [error, setError] = useState<boolean>(false)

  if (variant === 'logo')
    return (
      <LogoFlex>
        {error || !partner ? (
          !icon ? (
            <Icon type="briefcase" />
          ) : (
            icon
          )
        ) : (
          <LogoImg
            src={partner.logo ?? ''}
            onError={() => setError(true)}
            alt={partner?.name ?? ''}
          />
        )}
      </LogoFlex>
    )
  return (
    <IconFlex>
      {error || !partner ? (
        !icon ? (
          <Icon type="person" />
        ) : (
          icon
        )
      ) : (
        <IconImg
          src={partner.icon ?? ''}
          onError={() => setError(true)}
          alt={partner?.name ?? ''}
        />
      )}
    </IconFlex>
  )
}

const IconImg = styled.img`
  font-size: 0px;
  width: 16px;
  height: 16px;
`

const LogoImg = styled.img`
  font-size: 0px;
  height: 22px;
`

const IconFlex = styled(Center)`
  width: 16px;
  height: 16px;
`

const LogoFlex = styled(Center)`
  height: 22px;
`
