import styled from 'styled-components'
import { Typography } from '@/src/ui/components/Typography'
import { Space } from '@/src/components/ui/Space'
import { Truck } from '@/src/ui/icons/Truck'
import { Types } from '@parafin/error-handling'
import { Center, FlexCenter } from '@/src/ui/components/Flex'

export const ErrorDisplay = ({
  error,
}: {
  error?: Error & Types.BaseErrorType
}) => {
  const { displayMessage } = error || {}

  return (
    <ErrorBackground>
      <ErrorCard>
        <Truck color="#A1A2A4" />
        <Space amount="32px" />
        <Typography variant="title" rawColor="#A1A2A4">
          {displayMessage ??
            'Something went wrong on our end. We are looking into it!'}
        </Typography>
      </ErrorCard>
    </ErrorBackground>
  )
}

const ErrorBackground = styled(Center)`
  height: 70vh;
  width: 100%;
`

const ErrorCard = styled(FlexCenter)`
  flex-direction: column;
`
