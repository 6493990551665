import { Suspense, lazy, useEffect } from 'react'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import { Navigate } from '@/src/routes/Navigate'

import { SegmentProvider } from '@parafin/logging'
import { ErrorBoundary } from '@parafin/error-handling'
import { ThemeProvider } from '@/src/providers/theme'
import { QueryProvider } from '@/src/providers/queryProvider'
import { AuthProvider } from '@/src/providers/auth'
import { CoreProvider } from '@/src/providers/core'
import { ExperimentProvider } from '@/src/providers/experiment'
import { InitializeServices } from '@/src/providers/initServices'
import { FrameProvider } from '@/src/providers/frame'

import PageNotFound from '@/src/ui/components/PageNotFound'
import { RouteName } from '@/src/routes/RouteName'
import Toaster from '@/src/ui/components/Toaster'
import { ErrorDisplay } from '@/src/components/generic/ErrorDisplay'
import { ENVIRONMENT, SEGMENT_KEY } from '@/parafin.config'
import { getSessionId } from '@parafin/utils'

const Businesses = lazy(() => import('@/src/pages/businesses'))
const Analytics = lazy(() => import('@/src/pages/analytics'))
const Settings = lazy(() => import('@/src/pages/settings'))
const Developer = lazy(() => import('@/src/pages/developer'))
const Datashare = lazy(() => import('@/src/pages/datashare'))
const Marketing = lazy(() => import('@/src/pages/marketing'))
const GetStarted = lazy(() => import('@/src/pages/get-started'))

export const App = () => {
  return (
    <SegmentProvider
      segmentKey={SEGMENT_KEY}
      environment={ENVIRONMENT}
      sessionId={getSessionId()}
      appName="partner_dashboard"
    >
      <ErrorBoundary fallback={(error) => <ErrorDisplay error={error} />}>
        <ThemeProvider>
          <BrowserRouter>
            <QueryProvider>
              <AuthProvider>
                <CoreProvider>
                  <ExperimentProvider>
                    <InitializeServices />
                    <ScrollToTop />
                    <FrameProvider>
                      <Suspense fallback={<></>}>
                        <Routes>
                          <Route
                            path={`${RouteName.ANALYTICS}/*`}
                            element={<Analytics />}
                          />
                          <Route
                            path={`${RouteName.BUSINESSES}/*`}
                            element={<Businesses />}
                          />
                          <Route
                            path="customers/*"
                            element={
                              <Navigate
                                to={location.pathname.replace(
                                  'customers',
                                  'businesses'
                                )}
                              />
                            }
                          />
                          <Route
                            path={`${RouteName.DATA_SHARE}/*`}
                            element={<Datashare />}
                          />
                          <Route
                            path={`${RouteName.SETTINGS}/*`}
                            element={<Settings />}
                          />
                          <Route
                            path={`${RouteName.DEVELOPER}/*`}
                            element={<Developer />}
                          />
                          <Route
                            path={`${RouteName.MARKETING}/*`}
                            element={<Marketing />}
                          />
                          <Route index element={<GetStarted />} />
                          <Route path="*" element={<PageNotFound />} />
                        </Routes>
                      </Suspense>
                    </FrameProvider>
                    <Toaster />
                  </ExperimentProvider>
                </CoreProvider>
              </AuthProvider>
            </QueryProvider>
          </BrowserRouter>
        </ThemeProvider>
      </ErrorBoundary>
    </SegmentProvider>
  )
}

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
