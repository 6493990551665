import { ReactNode } from 'react'
import styled from 'styled-components'
import { PartnerLogo } from '../PartnerLogo'
import { Typography } from '../Typography'
import { PartnerInfo } from '@/src/providers/core'

type PartnerLogoWithNameProps = {
  partner?: PartnerInfo
  icon?: ReactNode
}

export const PartnerLogoWithName = ({
  partner,
  icon,
}: PartnerLogoWithNameProps) => {
  return (
    <IconAlignment>
      {partner === undefined ? (
        <Typography inline color="black70">
          (Not available)
        </Typography>
      ) : (
        <>
          <PartnerLogo partner={partner} icon={icon}></PartnerLogo>
          <Typography inline>{partner.name}</Typography>{' '}
          {partner.config && partner.config.country_code === 'CA' && (
            <Typography inline color="black70">
              (Canada)
            </Typography>
          )}
        </>
      )}
    </IconAlignment>
  )
}

const IconAlignment = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
`
